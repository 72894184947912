import { MedianPriceLink } from 'components/MedianPriceLink'
import { type InputType, MINVALUE, sandBoxNotEditableFields, SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting } from 'services'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { Checkbox } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { getPrice2decimal, getPrice3decimal, onSplitText, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { ILoan, ILoanDetail, ILoanProcess } from './interfaces'
import { calculateLTV, estimatedClosingAmountBorrower, isLtvAdjustments, isNonDSCRProduct } from './Logic'

interface IProps {
  loan_number: any
  loan: ILoan
  loanDetail: ILoanDetail
  loanProcessData: ILoanProcess
  showingFields: string[][]
  visibleFields: string[]
  loanFields: Record<string, InputType>
  isAdmin: boolean | undefined
  brokerCanNotEditDetail: boolean
  sandBoxMode: boolean
  canEditLoanDetail: boolean
  exceptionApproved: boolean
  canPriceAdjust: boolean
  isBorrower?: boolean
  anytimeCanEditLoanDetail: boolean | undefined
  changedLoanDetailJson: any
  originLoanDetail: ILoan | undefined
  maximumTotalLoanAmountTip: any
  priceAdjustmentObject: any
  fillLoanAmountMax: () => void
  onChangeLoanInputFields: (key: string, e: any) => Promise<void>
  onBlur: (key: string) => Promise<void>
  fillClosingAmountMax: (possibleConstructionReserveMin: number) => void
  onSelectNoScore: (value: boolean) => void
}

export const LoanDetailInputs = (props: IProps) => {
  const {
    loanFields,
    loan,
    loanDetail,
    loanProcessData,
    showingFields,
    visibleFields,
    originLoanDetail,
    loan_number,
    isAdmin,
    brokerCanNotEditDetail,
    changedLoanDetailJson,
    maximumTotalLoanAmountTip,
    priceAdjustmentObject,
    sandBoxMode,
    canEditLoanDetail,
    exceptionApproved,
    anytimeCanEditLoanDetail,
    canPriceAdjust,
    isBorrower = false,
    fillLoanAmountMax,
    onChangeLoanInputFields,
    onBlur,
    fillClosingAmountMax,
    onSelectNoScore,
  } = props

  const [tooltips, setTooltips] = useState<Record<string, string>>({})

  const isNewLoan = loan_number === 'New'

  useEffect(() => {
    getSetting(SettingKey.LOAN_STRUCTURE_TOOLTIPS).then(({ value }) => {
      const content = JSON.parse(value || '{}')

      setTooltips(content)
    })
  }, [])

  const disableLoanDetailField = (key: string) => {
    if (isBorrower) return true
    if (key === 'estimatedCreditScore' && loanDetail.submitToLoanSetup === 0) return false

    if (sandBoxMode) {
      if (sandBoxNotEditableFields.indexOf(key) !== -1) return true
      return false
    } else {
      if (exceptionApproved && !anytimeCanEditLoanDetail) return true
      if (canEditLoanDetail) return false
      return true
    }
  }

  const onShowHistory = async (key: string, table: string = 'Loan') => {
    const options = {
      table,
      field: key,
      keys: {
        field: key,
        loanNumber: loanDetail.loanNumber,
      },
    }
    openAuditLog(options)
  }

  return (
    <div>
      {showingFields.map((fields, index) => {
        const isLtvAdjustment = isLtvAdjustments(fields)
        const showLtvAdjustments = isLtvAdjustment && !isNewLoan && isAdmin
        const hideLtvAdjustments = isLtvAdjustment && !(!isNewLoan && isAdmin)

        if (hideLtvAdjustments) return <></>

        return (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-5 rounded-t border border-solid border-gray-200 shadow p-2 justify-around"
          >
            {showLtvAdjustments && (
              <div className=" col-span-2 text-[16px] font-variation-settings-600">Max LTV Adjustments</div>
            )}
            {fields.map((key, index1) => {
              let rlt = []
              let cn = loanFields[key]?.length === 1 ? 'md:col-span-2' : 'md:col-span-1'
              let tooltip = tooltips[key]
              let additionalElements = null
              let disabled = false
              if (brokerCanNotEditDetail || exceptionApproved) {
                if (changedLoanDetailJson[key] !== undefined) {
                  cn += ' ring-2 ring-red-400 rounded-t'
                  const changeString = `${(originLoanDetail as any)[key]} -> ${(loan as any)[key]}`
                  if (tooltip) tooltip = `${tooltip}<br/>${changeString}`
                  else tooltip = changeString
                }
              }
              if (key === 'subjectPropertyAddress') {
                additionalElements = <MedianPriceLink address={loan.subjectPropertyAddress} />
              }
              if (key === 'proposedLoanAmount') {
                if (isNonDSCRProduct(loan.productType)) {
                  if (
                    maximumTotalLoanAmountTip.minValue !== MINVALUE &&
                    removeComma(maximumTotalLoanAmountTip.minValue) !== removeComma(loan[key])
                  ) {
                    additionalElements = (
                      <span className={`text-shade-blue hover:underline cursor-pointer`}>
                        <Tooltip
                          message={`<div>Max Amount: ${getPrice3decimal(maximumTotalLoanAmountTip.minValue)}</div>`}
                        >
                          <span className="font-variation-settings-600 px-3" onClick={fillLoanAmountMax}>
                            Fill Max
                          </span>
                        </Tooltip>
                      </span>
                    )
                  }
                }
              }
              if (key === 'estimatedCreditScore') {
                additionalElements = (
                  <Tooltip message="No Score">
                    <Checkbox
                      color="shade-blue"
                      id="noScore"
                      checked={loan.isNoScore}
                      onChange={onSelectNoScore}
                      onBlur={() => onBlur(key)}
                    />
                  </Tooltip>
                )
                if (loan.isNoScore) disabled = true
              }
              if (key === 'estimatedDscr') {
              } else
                rlt.push(
                  <div key={`${index}-${index1}`} className={cn}>
                    <RenderInput
                      input={{
                        ...loanFields[key],
                        error:
                          priceAdjustmentObject[key] !== undefined && (loan as any)[key] !== ''
                            ? ''
                            : loanFields[key].error,
                        value: (loan as any)[key],
                        history: !isNewLoan,
                        disabled:
                          (disableLoanDetailField(key) || showLtvAdjustments ? !canPriceAdjust : false) || disabled,
                        tooltip: tooltip ? onSplitText(tooltip, 70) : tooltip,
                        additionalElements,
                      }}
                      Key={key}
                      onChange={onChangeLoanInputFields}
                      showHistory={onShowHistory}
                      onBlur={onBlur}
                    />
                  </div>,
                )
              if (key === 'constructionReserve') {
                const { rehabFinancedPercent, totalRehabFinancedPercent, totalRehabFinancedTooltip } =
                  estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)
                const {
                  minTotalConstructionFinanced,
                  maxTotalConstructionFinanced,
                  minConstructionFinancedHoldback,
                  maxConstructionFinancedHoldback,
                } = loanDetail.loanGlobalSettings

                const constructionFinancedHoldbackError =
                  removeComma(rehabFinancedPercent) > removeComma(maxConstructionFinancedHoldback) ||
                  removeComma(rehabFinancedPercent) < removeComma(minConstructionFinancedHoldback)
                    ? minConstructionFinancedHoldback === maxConstructionFinancedHoldback
                      ? `This may not be less than ${minConstructionFinancedHoldback}% of the Construction Budget`
                      : `Must be between ${minConstructionFinancedHoldback}% and ${maxConstructionFinancedHoldback}%`
                    : ''
                const totalRehabFinancedPercentError =
                  removeComma(totalRehabFinancedPercent) > removeComma(maxTotalConstructionFinanced) ||
                  removeComma(totalRehabFinancedPercent) < removeComma(minTotalConstructionFinanced)
                    ? maxTotalConstructionFinanced === minTotalConstructionFinanced
                      ? `This may not be less than ${minTotalConstructionFinanced}% of the Construction Budget`
                      : `Must be between ${minTotalConstructionFinanced}% and ${maxTotalConstructionFinanced}%`
                    : ''

                rlt.push(
                  <div key={`rehabFinancedPercent-${index}-${index1}`}>
                    <RenderInput
                      input={{
                        title: 'Construction Financed Holdback %',
                        inputType: 'text',
                        prefix: '%',
                        value: rehabFinancedPercent,
                        readOnly: true,
                        error: constructionFinancedHoldbackError,
                        tooltip: `'Construction Amount Financed'/<br/>'Construction Budget'`,
                      }}
                      Key={'rehabFinancedPercent'}
                      onChange={onChangeLoanInputFields}
                    />
                  </div>,
                )
                rlt.push(
                  <div key={`totalRehabFinancedPercent-${index}-${index1}`}>
                    <RenderInput
                      input={{
                        title: 'Total Construction Financed %',
                        inputType: 'text',
                        prefix: '%',
                        value: totalRehabFinancedPercent,
                        readOnly: true,
                        tooltip: totalRehabFinancedTooltip,
                        error: totalRehabFinancedPercentError,
                      }}
                      Key={'totalRehabFinancedPercent'}
                      onChange={onChangeLoanInputFields}
                    />
                  </div>,
                )
              }

              if (key === 'proposedLoanAmount') {
                let ltvTooltip = ''
                let ltvCN = 'md:col-span-1'
                if (brokerCanNotEditDetail || exceptionApproved) {
                  if (changedLoanDetailJson['ltv'] !== undefined) {
                    ltvCN += ' ring-2 ring-red-400 rounded-t'
                    ltvTooltip = `${calculateLTV(originLoanDetail)} -> ${calculateLTV(loan)}`
                  }
                }
                rlt.push(
                  <div key={`ltv-${index}-${index1}`} className={ltvCN}>
                    <RenderInput
                      input={{
                        title: 'LTV',
                        inputType: 'text',
                        prefix: '%',
                        value: calculateLTV(),
                        readOnly: true,
                        tooltip: ltvTooltip,
                      }}
                      Key={'LTV'}
                      onChange={onChangeLoanInputFields}
                    />
                  </div>,
                )

                let cltvTooltip = ''
                let cltvCN = 'md:col-span-1'
                if (brokerCanNotEditDetail || exceptionApproved) {
                  if (changedLoanDetailJson['cltv'] !== undefined) {
                    cltvCN += ' ring-2 ring-red-400 rounded-t'
                    cltvTooltip = `${calculateLTV(originLoanDetail, true)} -> ${calculateLTV(loan, true)}`
                  }
                }
                rlt.push(
                  <div key={`cltv-${index}-${index1}`} className={cltvCN}>
                    <RenderInput
                      input={{
                        title: 'CLTV',
                        inputType: 'text',
                        prefix: '%',
                        value: calculateLTV(null, true),
                        readOnly: true,
                        tooltip: cltvTooltip,
                      }}
                      Key={'CLTV'}
                      onChange={onChangeLoanInputFields}
                    />
                  </div>,
                )
              }
              if (key === 'closingCostEstimate') {
                let possibleConstructionReserveMin = -1
                let additionalElements = null
                let { value, valueWithoutConstructionCalc, closingTooltip, closingMaxRehabBudget, closingError } =
                  estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)
                if (loan.transactionType === 'Refinance' && visibleFields.includes('rehabBudget')) {
                  if (maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation !== -1) {
                    possibleConstructionReserveMin = Math.max(
                      maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation,
                      valueWithoutConstructionCalc - closingMaxRehabBudget,
                    )
                    if (possibleConstructionReserveMin < removeComma(loan.constructionReserve)) {
                    } else {
                      possibleConstructionReserveMin = -1
                    }
                  }

                  if (value > closingMaxRehabBudget) {
                    possibleConstructionReserveMin = valueWithoutConstructionCalc - closingMaxRehabBudget
                  }
                  if (possibleConstructionReserveMin >= 0) {
                    additionalElements = (
                      <span className={`text-shade-blue hover:underline cursor-pointer`}>
                        <Tooltip
                          message={`<div>Max Amount: $${getPrice3decimal(
                            valueWithoutConstructionCalc - possibleConstructionReserveMin,
                          )}<br/>Construction Amount Financed:<br/>$${getPrice3decimal(
                            loan.constructionReserve,
                          )} -> $${getPrice3decimal(possibleConstructionReserveMin)}</div>`}
                        >
                          <span
                            className="font-variation-settings-600"
                            onClick={() => fillClosingAmountMax(possibleConstructionReserveMin)}
                          >
                            Fill Max
                          </span>
                        </Tooltip>
                      </span>
                    )
                  }
                } else {
                  if (
                    maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation !== -1 &&
                    maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation <
                      removeComma(loan.constructionReserve) &&
                    visibleFields.includes('constructionReserve')
                  ) {
                    additionalElements = (
                      <span className={`text-shade-blue hover:underline cursor-pointer`}>
                        <Tooltip
                          message={`<div>Min Amount: $${getPrice3decimal(
                            value -
                              removeComma(loan.constructionReserve) +
                              maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation,
                          )}<br/>Construction Amount Financed:<br/>$${getPrice3decimal(
                            loan.constructionReserve,
                          )} -> $${getPrice3decimal(
                            maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation,
                          )}</div>`}
                        >
                          <span
                            className="font-variation-settings-600"
                            onClick={() =>
                              fillClosingAmountMax(
                                maximumTotalLoanAmountTip.constructionReserveMinwithoutChangeLoanAmountValidation,
                              )
                            }
                          >
                            Fill Min
                          </span>
                        </Tooltip>
                      </span>
                    )
                  }
                }

                rlt.push(
                  <div key={`atClosingBorrowerAmount-${index}-${index1}`} className={cn}>
                    <RenderInput
                      input={{
                        title: `Estimated Closing Amount ${
                          loan.transactionType === 'Refinance' ? 'to' : 'from'
                        } Borrower`,
                        inputType: 'text',
                        prefix: '$',
                        value: getPrice2decimal(value),
                        readOnly: true,
                        tooltip: closingTooltip,
                        error: closingError,
                        additionalElements: additionalElements,
                      }}
                      Key={'atClosingBorrowerAmount'}
                      onChange={onChangeLoanInputFields}
                    />
                  </div>,
                )
              }
              if (key === 'estimatedDscr') {
                const pitis = combinedMonthlyExpense(loan, loanProcessData, true)
                if (pitis['dscr'] !== null) {
                  // const error =
                  //   removeComma(pitis['dscr']) !== removeComma(loan.estimatedDscr)
                  //     ? `The 'DSCR' does not match the 'Calculated DSCR'!`
                  //     : ''
                  const tooltip = `${loan.proposedMonthlyRent} = (Rent) <br/>/<br/> ${pitis['sum']} = (Mortgage Payment:${pitis['mortgagePayment']} + Insurance + Taxes + HoaDues + <br/> Second Lien Payment)`
                  rlt.push(
                    <div key={`calculatedDSCR-${index}-${index1}`} className={'md:col-span-1'}>
                      <RenderInput
                        input={{
                          title: 'Calculated DSCR',
                          inputType: 'text',
                          prefix: '%',
                          tooltip,
                          value: pitis['dscr'],
                          // error,
                          readOnly: true,
                        }}
                        Key={'estimatedDscr'}
                        onChange={onChangeLoanInputFields}
                      />
                    </div>,
                  )
                }
              }
              return rlt
            })}
          </div>
        )
      })}
    </div>
  )
}
