import { CheckIcon, ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { setLoanData } from 'actions'
import { useEffect, useRef, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useDispatch } from 'react-redux'
import { createAddressDocument, validateAddress } from 'services'
import { svgLoading } from 'stories/assets'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'

import { EditAddressDialog } from './EditAddressDialog'

interface GoogleAutoCompleteProps {
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is readOnly
   */
  readOnly?: boolean
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Input key of Input
   */
  inputKey?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * Show History
   */
  history?: boolean

  additionalElements?: JSX.Element | null

  /**
   * Required
   */
  required?: boolean
  /**
   * Editable
   */
  editable?: boolean
  requireValidate?: boolean

  allowForeign?: boolean

  onChange: (e: any) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  showHistory?: () => void
}

enum ValidateStatus {
  Failed = -2,
  Warning = -1,
  Nothing = 0,
  Loading = 1,
  Success = 2,
}

/**
 * Primary UI component for user interaction
 */
export const GoogleAutoComplete = ({
  disabled = false,
  readOnly = false,
  title = '',
  placeholder = ' ',
  tooltip = '',
  value: _value = '',
  inputKey = 'google-map-auto',
  error = '',
  required = false,
  additionalElements = null,
  editable = true,
  requireValidate = true,
  className = '',
  history = false,
  allowForeign = false,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
}: GoogleAutoCompleteProps) => {
  const classNames = [
    'block',
    'rounded-t',
    'px-2.5',
    'pb-[2px]',
    'pt-[27px]',
    'w-full',
    'text-[15px]',
    'text-gray-900',
    disabled ? 'bg-gray-100' : 'bg-white',
    readOnly ? 'cursor-not-allowed' : '',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-sky-600`,
    'peer',
    error && 'border-rose-700',
    className,
  ]
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string | null>(null)
  const [isShowEdit, setShowEdit] = useState(false)
  const [requestingAddr, setRequestingAddr] = useState('')
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>(ValidateStatus.Nothing)
  const [validatedAddr, setValidatedAddr] = useState('')
  const [validateTimer, setValidateTimer] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (!_value && !!value) return
    setValue(_value)
  }, [_value])

  useEffect(() => {
    if (value !== null && value != _value) onChange(value)
  }, [value])

  useEffect(() => {
    if (inputRef.current) (inputRef.current as any).value = value
  }, [value])

  const dispatch = useDispatch()

  const isSubjectPropertyAddress = inputKey === 'subjectPropertyAddress'

  const change = (e: any) => {
    let value: string = e.target.value
    if (!allowForeign && value.endsWith('USA')) {
      const index = value.lastIndexOf(', USA')
      value = value.substring(0, index)
    }
    setValue(value)
    inputRef?.current?.focus()
    setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        inputRef?.current?.blur()
      }
    }, 250)
    if (isSubjectPropertyAddress) {
      if (e.target.county) {
        dispatch(setLoanData({ key: 'propertyCounty', data: e.target.county }))
      }
    }
    if (validateTimer) {
      clearTimeout(validateTimer)
      setValidateTimer(undefined)
    }
  }

  const blur = (e: any) => {
    onBlur()
    const value = e.target.value
    if (requestingAddr == value) return

    if (allowForeign) return
    if (!value) {
      setValidateStatus(ValidateStatus.Nothing)
      return
    }

    if (validateTimer) clearTimeout(validateTimer)

    setRequestingAddr(value || '')
    const timer = setTimeout(() => {
      if (!value) return
      setValidateStatus(ValidateStatus.Loading)

      validateAddress(value!).then(({ success, address }) => {
        setValidateTimer(undefined)
        if (success) setValidateStatus(ValidateStatus.Success)
        else if (address) setValidateStatus(ValidateStatus.Warning)
        else setValidateStatus(ValidateStatus.Failed)

        setValidatedAddr(address)
      })
    }, 250)

    setValidateTimer(timer)
  }

  const editAddress = () => {
    setShowEdit(true)
  }

  const onChangeText = async (address: string) => {
    if (address) {
      const e = {
        target: {
          value: address,
        },
      }
      change(e)
    }
    setShowEdit(false)
  }

  const generateAddressDocument = () => {
    if (!value) return
    setValidateStatus(ValidateStatus.Loading)

    createAddressDocument(value).finally(() => setValidateStatus(ValidateStatus.Success))
  }

  const renderValidate = () => {
    switch (validateStatus) {
      case ValidateStatus.Nothing:
        return <></>
      case ValidateStatus.Loading:
        return <img src={svgLoading} className="w-[14px] h-[14px] text-white animate-spin" />
      case ValidateStatus.Success:
        return <CheckIcon className="h-[14px] w-[14px] text-green-600 cursor-pointer" aria-hidden="true" />
      case ValidateStatus.Warning:
        return (
          <p className="bg-yellow-300 rounded-full h-[14px] w-[14px] text-white cursor-pointer text-center leading-3">
            !
          </p>
        )
      case ValidateStatus.Failed:
        return (
          <p className="bg-red-500 rounded-full h-[14px] w-[14px] text-white cursor-pointer text-center leading-3">x</p>
        )
    }
  }

  if (value === null) return <div />

  return (
    <div className="input-container w-full">
      <div className={`group relative ${additionalElements !== null ? '' : 'z-0'} w-full`}>
        {readOnly ? (
          <input className={classNames.join(' ')} disabled={true} value={value} />
        ) : (
          <Autocomplete
            ref={inputRef}
            apiKey={'AIzaSyA2TV23UkRc7jDyLklenO902VTvnECVBOU'}
            onPlaceSelected={(place) => {
              if (place.address_components) {
                let county = ''
                let locality = ''
                for (let i = 0; i < place.address_components.length; i += 1) {
                  const item = place.address_components[i]
                  if (item.types.indexOf('administrative_area_level_2') !== -1) county = item.long_name
                  if (item.types.indexOf('locality') !== -1) locality = item.long_name
                }
                if (county.length === 0 && locality.length) {
                  county = locality + ' city'
                }
                const e = {
                  target: {
                    value: place.formatted_address,
                    county: county,
                    state: place.address_components[5]?.short_name || '',
                  },
                }
                change(e)
              }
            }}
            onBlur={blur}
            className={classNames.join(' ')}
            inputAutocompleteValue={value}
            defaultValue={value}
            placeholder={placeholder}
            options={{ types: ['address'], componentRestrictions: allowForeign ? {} : { country: 'us' } }}
            key={`google-map-auto-${inputKey}`}
          />
        )}

        <label className="absolute text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center">
          {title}
          {required && '*'}
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {additionalElements !== null && additionalElements}
          {requireValidate && validateStatus != ValidateStatus.Nothing && (
            <span
              className="ml-1"
              onClick={() => {
                if (isSubjectPropertyAddress && validateStatus == ValidateStatus.Success) generateAddressDocument()
              }}
            >
              {renderValidate()}
            </span>
          )}
          {history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
          {!readOnly && editable && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => editAddress()}>
              <PencilSquareIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </label>
        {validateStatus == ValidateStatus.Warning && validatedAddr && (
          <div
            className="absolute border border-gray-400 px-1 py-0.5 bg-white rounded-sm w-max right-0 top-0 hidden group-hover:inline hover:bg-gray-200 cursor-pointer text-sm"
            onClick={() => {
              setValue(validatedAddr)
              ;(inputRef.current as any).value = validatedAddr
              inputRef?.current?.focus()
              setTimeout(() => {
                if (document.activeElement === inputRef.current) {
                  inputRef?.current?.blur()
                }
              }, 250)
            }}
          >
            {validatedAddr}
          </div>
        )}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}

      {editable && isShowEdit && <EditAddressDialog address={value} onSubmit={onChangeText} />}
    </div>
  )
}
