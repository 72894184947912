import { capitalizeFirstLetter } from 'utils'

export const COMPANY_ID = process.env.REACT_APP_COMPANY_ID
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME as string
export const COMPANY_NAME_C = `${COMPANY_NAME}`.toUpperCase()
export const COMPANY_NAME_FC = capitalizeFirstLetter(COMPANY_NAME)

export const COMPANY_TITLE = process.env.REACT_APP_COMPANY_TITLE as string

export const COMPANY_DNS = process.env.REACT_APP_COMPANY_DNS as string

export const COMPANY_NMLS = process.env.REACT_APP_COMPANY_NMLS as string

export const COMPANY_PHONE = process.env.REACT_APP_COMPANY_PHONE as string
export const COMPANY_PHONE_NUM = COMPANY_PHONE.match(/\d+/g)?.join('')

export const COMPANY_PHONE_FREE = process.env.REACT_APP_COMPANY_PHONE_FREE as string
export const COMPANY_PHONE_FREE_NUM = COMPANY_PHONE_FREE.match(/\d+/g)?.join('')

export const COMPANY_ADDRESS = process.env.REACT_APP_COMPANY_ADDRESS

export const COMPANY_LOGO = process.env.REACT_APP_COMPANY_LOGO

export const COMPANY_EMAIL = process.env.REACT_APP_COMPANY_EMAIL

export const COMPANY_FACEBOOK_URL = process.env.REACT_APP_COMPANY_FACEBOOK_URL
export const COMPANY_TWITTER_URL = process.env.REACT_APP_COMPANY_TWITTER_URL
export const COMPANY_INSTAGRAM_URL = process.env.REACT_APP_COMPANY_INSTAGRAM_URL

export const COMPANY_LANDING_URL = (process.env.REACT_APP_API_URL || '')
  .replace('api.commercial.', '')
  .replace('-', '.')

export const IS_MAIN_COMPANY = COMPANY_ID == 'nextres'
export const IS_FINRESI_SYNC = COMPANY_ID == 'nextres'
