export const BrokerAgreementPlaceholders: string[] = [
  'address',
  'title',
  'name',
  'email',
  'phone',
  'phoneExt',
  'brokerSSN',
  'brokerNmls',
  'todayDate',
  'companyAddress',
  'companyTitle',
]
