import { baseUrl } from './api.url.constants'
export * from './api.status.constants'
export * from './api.url.constants'
export * from './input.type.constants'
export * from './loan.bytepro.constants'
export * from './loan.constants'
export * from './loan.status.constants'
export * from './loan.type.constants'
export * from './user.type.constants'

export const itemCountPerPage = 10
export const pullCreditReportLimitDays = 30
export const TimeZone = 'America/New_York'

export const daysOfWeek: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export const monthsInYear: Record<number, string> = {
  0: '',
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
  13: 'Closing Date',
}

export interface BaseFile {
  id: number
  name: string
  fileKey: string
  createdAt: number
}

export * from './company.constants'
import { COMPANY_DNS, COMPANY_NAME_FC } from './company.constants'

export const ServicingEmail = `servicing@${COMPANY_DNS}`

export const isAppDev = process.env.REACT_APP_DEV === 'true'

export const companyName = COMPANY_NAME_FC

export const appApiUrl = baseUrl

export const SecPerDay = 24 * 3600 * 1000

export enum SettingKey {
  CONDITION_NO = 'CONDITION_NO',
  CONDITION_TEMPLATE_NO = 'CONDITION_TEMPLATE_NO',
  TASK_TEMPLATE_NO = 'TASK_TEMPLATE_NO',
  ROLODEX_NO = 'ROLODEX_NO',
  LOAN_NO = 'LOAN_NO',
  TASK_NO = 'TASK_NO',
  ASSIGNMENT_NAMES = 'ASSIGNMENT_NAMES',
  FLOOR_NO = 'FLOOR_NO',
  BUSINESS_DAYS = 'BUSINESS_DAYS',
  RATESHEET_NO = 'RATESHEET_NO',
  COMPANY_BROADCAST = 'COMPANY_BROADCAST',
  BROKER_VERIFICATION = 'BROKER_VERIFICATION',
  CORRESPONDENT_VERIFICATION = 'CORRESPONDENT_VERIFICATION',
  TABLEFUNDER_VERIFICATION = 'TABLEFUNDER_VERIFICATION',
  BUSINESS_HOURS = 'BUSINESS_HOURS',
  REVIEW_FORM_CATEGORIES = 'REVIEW_FORM_CATEGORIES',
  REVIEW_FORM_TEMPLATE_NO = 'REVIEW_FORM_TEMPLATE_NO',
  ARM_DETAIL_NO = 'ARM_DETAIL_NO',
  LOAN_PROGRAM_NO = 'LOAN_PROGRAM_NO',
  TABLEFUNDER_PDF = 'TABLEFUNDER_PDF',
  CORRESPONDENT_PDF = 'CORRESPONDENT_PDF',
  DATAVERIFY_TEST_LOANNUMBER = 'DATAVERIFY_TEST_LOANNUMBER',
  DATAVERIFY_TEST_CODE = 'DATAVERIFY_TEST_CODE',
  SERVICE_NOTES_ORDER = 'SERVICE_NOTES_ORDER',
  BUDGET_ITEMS_MAPPING = 'BUDGET_ITEMS_MAPPING',
  BUDGET_TEMPLATE = 'BUDGET_TEMPLATE',
  BORROWER_CONDITIONS = 'BORROWER_CONDITIONS',
  HOMEPAGE_CREDIT_BOX = 'HOMEPAGE_CREDIT_BOX',
  CONTRACTOR_NO = 'CONTRACTOR_NO',
  GENERATE_DOCUMENT_NO = 'GENERATE_DOCUMENT_NO',
  ACCOUNTING_CATEGORIES = 'ACCOUNTING_CATEGORIES',
  DOCMAGIC_WORKSHEET_ID = 'DOCMAGIC_WORKSHEET_ID',
  GLOBAL_SETTINGS = 'GLOBAL_SETTINGS',
  LOAN_TERM_SHEET = 'LOAN_TERM_SHEET',
  UNDERWRITING_FEE = 'UNDERWRITING_FEE',
  BORROWER_NO = 'BORROWER_NO',
  COMPANY_LOGO_URL = 'COMPANY_LOGO_URL',
  LOAN_STRUCTURE_TOOLTIPS = 'LOAN_STRUCTURE_TOOLTIPS',
  TEXT_CONFIGURATION = 'TEXT_CONFIGURATION',
  FROM_EMAILS = 'FROM_EMAILS',
}
