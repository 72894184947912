import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  ClockIcon,
  CloudArrowUpIcon,
  DocumentCheckIcon,
  EnvelopeIcon,
  EyeIcon,
  ListBulletIcon,
  MinusIcon,
  PencilSquareIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { ClosingParty } from 'components/ClosingParty'
import { SourceBox, TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanProcessSelection } from 'components/LoanProcessSelection'
import { getLoanStatusChangeErrorMessage } from 'components/LoanStatus'
import { MedianPriceLink } from 'components/MedianPriceLink'
import {
  AddConditionsFromTemplates,
  AddHoc,
  ChooseDocumentsForCondition,
  ConditionOrderChange,
  DeleteConditions,
} from 'components/Modals'
import { PlainInput } from 'components/PlainInput'
import { AccountType, COMPANY_NAME_FC, CONDITION_TEMPLATE_REQUIRED, loanStatusConstants } from 'config'
import { acceptedFileTypes } from 'config/file.type'
import { usePermissions } from 'hooks/usePermissions'
import type { IBrokerCondition } from 'pages/Admin'
import { IWorkflowConfig } from 'pages/Admin/AdminTools/Configuration/Workflows/Workflows'
import { categories, condConstant } from 'pages/Admin/ConditionsAndTemplates/constant'
import { assetInfoValidate } from 'pages/LoanApplication/AssetInformation/logics'
import { isSameStructureApplicationCreditScore } from 'pages/LoanApplication/CreditScore/logic'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { closingPartiesMap, LoanType, partiesMap } from 'reducers/loanDetail.reducer'
import { getLoanSubmission, uploadFiles } from 'services'
import {
  downloadFile,
  generateNewConditionNo,
  getAdminConfig,
  getLoanSubmissionPdf,
  getSSAPdf,
  openS3Document,
  postReviewRequest,
  postSubmitClosedLoanReview,
  postSubmitClosingConditionCleared,
  postSubmitConditions,
  postSubmitToAE,
  postSubmitToLoanSetup,
  postSubmitToUnderwriter,
  saveLoanProcess,
  setLoanSubmissionTemplate,
  submitCTCReview,
  updateLoanSubmission,
} from 'services/apis'
import { loanSubmissionPdfData, LoanSubmissionPdfType } from 'services/pdfs/loan'
import { svgLoading } from 'stories/assets'
import {
  Button,
  ButtonGroup,
  Checkbox,
  CopyableText,
  DocumentFile,
  MultiSelect,
  Select,
  Select2,
  TextArea,
} from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  confirm,
  formatDate,
  formatTime,
  multiConfirm,
  openAuditLog,
  PermissionGate,
  renderHeader,
  replaceNextLineToBr,
  sortByKey,
} from 'utils'

import { actionConditionNos, docCategory, docStatus, exceptionStatus } from './constants'
import { DocumentHistoryModal } from './DocumentHistoryModal'
import { DocumentsSlide } from './documents'
import { LoanDetailsSlide } from './loanDetails'
import { NotifyBorrowerSlide } from './NotifyBorrower'
import { RushSubmitModal } from './RushSubmitModal'
import { ScrollTop } from './ScrollTop'
import { SendPdfEmailDialog } from './SendPdfEmailDialog'
import type { LoanSubmissionCondition, LoanSubmissionDocument } from './types'
import { UnderwritingSlide } from './underwriting'

export function LoanSubmission() {
  const categoriesRef = useRef<Array<HTMLDivElement | null>>([])

  const { auth, loanDetail, step, borrower, coBorrower } = useSelector((state: any) => {
    return {
      auth: state.auth,
      loanDetail: state.loanDetail,
      step: state.step,
      borrower: state.borrower.borrower,
      coBorrower: state.borrower.coBorrower,
    }
  })
  const accountType = auth.profile.accountType
  const profile = useSelector((state: any) => state.auth.profile)
  const dispatch = useDispatch()
  const canDeleteDocumentPermanently =
    [AccountType.ADMIN, AccountType.OPERATION_SUPERVISOR, AccountType.UNDERWRITER, AccountType.UW_MANAGER].indexOf(
      profile.accountType,
    ) !== -1

  const { parties } = loanDetail

  const [action, setAction] = useState('')
  const [loading, setLoading] = useState(false)
  const [loanNumber, setLoanNumber] = useState(0)
  const [templates, setTemplates] = useState<Record<number, string>>({})
  const [currentTemplate, setCurrentTemplate] = useState(-1)
  const [categoryOpenStatus, setCategoryOpenStatus] = useState<Record<string, boolean>>({
    'Loan Intake': true,
    PTD: true,
    PTF: true,
  })
  const [submissions, setSubmissions] = useState<Array<any>>([])
  const [conditions, setConditions] = useState<LoanSubmissionCondition[]>([])
  const [editingCondition, setEditingCondition] = useState<LoanSubmissionCondition | null>(null)
  const [documents, setDocuments] = useState<LoanSubmissionDocument[]>([])
  const [conditionOrder, setConditionOrder] = useState<Array<number>>([])
  const [reviewStatus, setReviewStatus] = useState<any>({})
  const [conditionReviewNos, setConditionReviewNos] = useState<any>({})

  const [filterCategory, setFilterCategory] = useState('')
  const [filterCleared, setFiltercleared] = useState(false)
  const [conditionSortBy, setConditionSortBy] = useState('')
  const [documentsSortBy, setDocumentsSortBy] = useState<Record<number, { key: string; order: number }>>({})
  const [isSelectDocuments, setSelectDocuments] = useState<Record<number, Record<string, boolean>>>({})

  const [originItemExpands, setOriginItemExpands] = useState<Record<string, boolean>>({})

  const [editingConditionNo, setEditingConditionNo] = useState(0)
  const [respFilter, setRespFilter] = useState<Record<string, boolean>>({})
  const [submissionUpdatedAt, setSubmissionUpdatedAt] = useState<number>(0)
  const [lastSubmittedAt, setLastSubmittedAt] = useState(0)
  const [sendPdfEmailType, setSendPdfEmailType] = useState<LoanSubmissionPdfType | null>(null)

  const [historyDocument, setHistoryDocument] = useState<Record<string, any> | null>(null)

  const [scopeOfWork, setScopeOfWork] = useState(false)
  const [overrideLoanSubmissionRestriction, setOverrideLoanSubmissionRestriction] = useState(false)

  const [brokerConditions, setBrokerConditions] = useState<IBrokerCondition[]>([])

  const [showRushSubmit, setShowRushSubmit] = useState(false)

  const [restrictionConfig, setRestrictionConfig] = useState<Record<string, IWorkflowConfig>>({})
  const [assetInfoValidation, setAssetInfoValidation] = useState(false)

  const { hasPermission } = usePermissions()

  const canEditCondition = hasPermission('LOAN_SUBMISSION_EDIT_CONDITION')

  useEffect(() => {
    getAdminConfig('workflows').then((value) => {
      setRestrictionConfig(value?.loan_submission)
    })
    assetInfoValidate(false).then((value) => {
      setAssetInfoValidation(value)
    })
  }, [])
  useEffect(() => {
    const params = location.pathname.split('/')
    const loanNumber = params[3]
    if (!loanNumber || isNaN(parseInt(loanNumber))) return

    setLoanNumber(parseInt(loanNumber))
    setLoading(true)
    getLoanSubmission().then(
      ({
        conditionReviewNos,
        reviewStatus,
        templateNumber,
        conditions,
        documents,
        conditionOrder,
        submissions,
        updatedAt,
        lastSubmittedAt,
        templates,
        scopeOfWorkDone,
        brokerConditions,
      }) => {
        setConditionReviewNos(conditionReviewNos)
        setReviewStatus(reviewStatus)
        setConditions(conditions)
        setConditionOrder(conditionOrder)
        setCurrentTemplate(templateNumber)
        setDocuments(documents)
        setSubmissions(submissions)
        setLoading(false)
        setSubmissionUpdatedAt(new Date(updatedAt).getTime())
        setLastSubmittedAt(Number(lastSubmittedAt))
        setTemplates(templates)
        setScopeOfWork(scopeOfWorkDone)
        if (templateNumber == 0) setBrokerConditions(brokerConditions)
      },
    )
  }, [location.pathname])

  const getCountPerCategory = (category: string) => {
    return conditions.filter((condition) => {
      if (condition.category !== category) return false
      if (filterCleared && condition.cleared) return false
      if (respFilter[condition.responsibility] === false) return false
      return true
    }).length
  }

  const countCategories = useMemo(() => {
    let rlt: any = {}
    conditions.map((condition) => {
      if (rlt[condition.category] === undefined) {
        rlt[condition.category] = {
          cleared: 0,
          total: 0,
        }
      }
      rlt[condition.category].total += 1
      if (condition.cleared) rlt[condition.category].cleared += 1
    })
    let result: any = []
    categories.map((key) => {
      result.push(rlt[key] ? `${key} (${rlt[key].cleared}/${rlt[key].total})` : key)
    })
    return result
  }, [conditions])

  const isNeedToResubmit = useMemo(() => {
    const result = 0 < Number(lastSubmittedAt) && Number(lastSubmittedAt) < Number(submissionUpdatedAt) - 40000
    return result
  }, [submissionUpdatedAt, lastSubmittedAt])

  const onChangeConditionTemplate = async (value: number) => {
    const restriction = restrictionConfig.submit_to_loan_setup
    if (loanDetail.byteproFileName === 0 && restriction.submitToLoanSetupRequired.enabled && restriction.isActive) {
      return toast('Submit to LoanSetup is Required before Change!', { type: 'error' })
    }
    value = Number(value)
    if (!value) return
    const content = (
      <div className="text-gray-600 mb-4 text-md">
        Are you sure to change condition template?
        <div className="text-gray-900 text-left text-md flex items-center justify-center mt-4">
          {templates[currentTemplate] || 'Origin'}{' '}
          <span className="mx-4">
            <ArrowRightIcon className="w-4 h-4"></ArrowRightIcon>
          </span>{' '}
          {templates[value]}
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    value = parseInt(String(value))
    setLoading(true)
    setAction('chooseTemplate')

    setLoanSubmissionTemplate(value)
      .then(({ conditions, conditionOrder }: { conditions: LoanSubmissionCondition[]; conditionOrder: number[] }) => {
        setCurrentTemplate(value)
        setConditions(conditions)
        setConditionOrder(conditionOrder)
        setSubmissionUpdatedAt(Date.now())
      })
      .finally(() => {
        setLoading(false)
        setTimeout(() => {
          setAction('')
        }, 4000)
      })
  }

  const onShowTemplateHistory = () => {
    const options = {
      table: 'LoanProcess',
      field: 'templateNumber',
      keys: {
        field: 'templateNumber',
        loanNumber: loanNumber,
      },
    }
    openAuditLog(options)
  }

  const onAddCondition = async (data: Record<string, any>) => {
    data.name = data.name.trim()
    const includedFromCurrent = conditions.find((item: Record<string, any>) => item.name == data.name)
    if (includedFromCurrent) {
      toast('The condition name is already exists.', { type: 'error' })
      return false
    }
    setLoading(true)
    try {
      const { conditionNo } = await generateNewConditionNo(data.name)
      const newData = cloneDeep(conditions)
      const newCondition = {
        no: conditionNo,
        ...data,
      } as LoanSubmissionCondition
      newData.push(newCondition)
      setConditions(newData)

      const newConditionOrder = cloneDeep(conditionOrder)
      if (!newConditionOrder.includes(conditionNo)) newConditionOrder.push(conditionNo)
      setConditionOrder(newConditionOrder)

      await updateLoanSubmission({ update: [newCondition] }, {}, { set: newConditionOrder })
      setSubmissionUpdatedAt(Date.now())
      setLoading(false)
      return true
    } catch (e) {
      setLoading(false)
      return false
    }
  }

  const onAddConditionsFromTemplates = async (conds: Array<any>) => {
    const newData = cloneDeep(conditions)
    newData.push(...conds)
    setConditions(newData)

    const newConditionOrder = cloneDeep(conditionOrder)
    conds.forEach(({ no: conditionNo }) => {
      if (!newConditionOrder.includes(conditionNo)) newConditionOrder.push(conditionNo)
    })
    setConditionOrder(newConditionOrder)

    setLoading(true)
    await updateLoanSubmission({ update: conds }, {}, { set: newConditionOrder })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onChangeFilterCategory = (value: string) => {
    setFilterCategory(value)

    if (value != '0') setCategoryOpenStatus({ [value]: true })
    else setCategoryOpenStatus({})
  }

  const onSetConditionOrder = async (newConditionOrder: Array<number>) => {
    setConditionOrder(newConditionOrder)
    setLoading(true)
    await updateLoanSubmission({}, {}, { set: newConditionOrder })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onRemoveMultiConditions = async (conditionNos: Array<number>) => {
    const newData = cloneDeep(conditions)
    const newOrderData = cloneDeep(conditionOrder)
    const newDocuments = cloneDeep(documents)
    const removedConds: Array<any> = []
    const updatedDocs: Array<any> = []

    conditionNos.forEach((conditionNo) => {
      const index = newData.findIndex((cond: any) => cond.no == conditionNo)

      if (index === -1) return
      removedConds.push(newData[index])
      newData.splice(index, 1)

      const orderIndex = newOrderData.indexOf(conditionNo)
      if (orderIndex != -1) newOrderData.splice(orderIndex, 1)
    })

    newDocuments.forEach((document) => {
      const { conditionNo } = document
      const isInclude = conditionNos.includes(conditionNo)
      if (isInclude) {
        document.conditionNo = 0
        updatedDocs.push(document)
      }
    })

    setConditions(newData)
    setConditionOrder(newOrderData)
    setDocuments(newDocuments)
    setLoading(true)

    await updateLoanSubmission({ delete: removedConds }, { update: updatedDocs }, { set: newOrderData })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onClickCategory = (value: string) => {
    const newStatus = cloneDeep(categoryOpenStatus)
    newStatus[value] = !newStatus[value]
    setCategoryOpenStatus(newStatus)
  }

  const onEditCondition = (condition: LoanSubmissionCondition) => {
    setEditingCondition(condition)
  }

  const onRemoveCondition = async (condition: LoanSubmissionCondition) => {
    const content = (
      <div className="text-gray-600 mb-4 text-md">
        Are you sure want to delete this condition?
        <div className="text-gray-900 flex flex-col text-left text-sm">
          <span>No: {condition.no}</span>
          <span>Type: {condition.type}</span>
          <span>Class: {condition.class}</span>
          <span>Int/Ext: {condition.intext ? 'Yes' : 'No'}</span>
          <span>Responsibility: {condition.responsibility}</span>
          <span>Category: {condition.category}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    const index = conditions.findIndex((item) => item.no === condition.no)
    const newData = cloneDeep(conditions)
    const removedConds = newData.splice(index, 1)
    setConditions(newData)

    const newDocs = cloneDeep(documents)
    const updatedDocs: Array<any> = []
    newDocs.forEach((document) => {
      if (document.conditionNo != condition.no) return
      document.conditionNo = 0
      updatedDocs.push(document)
    })
    setDocuments(newDocs)
    setLoading(true)

    const orderIndex = conditionOrder.indexOf(condition.no)
    conditionOrder.splice(orderIndex, 1)
    await updateLoanSubmission({ delete: removedConds }, { update: updatedDocs }, { set: conditionOrder })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const setEditingConditionValue = (key: string, value: string) => {
    if (!editingCondition) return
    const newData = cloneDeep(editingCondition)
    newData[key] = value
    setEditingCondition(newData)
  }

  const onCancelEditingCondition = () => {
    setEditingCondition(null)
  }

  const onUpdateEditingCondition = async () => {
    if (!editingCondition) return
    const newData = cloneDeep(conditions)
    const index = conditions.findIndex((item) => item.no === editingCondition.no)
    newData[index] = {
      ...newData[index],
      type: editingCondition.type,
      class: editingCondition.class,
      responsibility: editingCondition.responsibility,
      category: editingCondition.category,
      name: editingCondition.name ? editingCondition.name.trim() : '',
      note: editingCondition.note ? editingCondition.note.trim() : '',
      description: editingCondition.description ? editingCondition.description.trim() : '',
      brokerDescription: editingCondition.brokerDescription ? editingCondition.brokerDescription.trim() : '',
    }
    if (!brokerProfile)
      newData[index].internalNote = editingCondition.internalNote ? editingCondition.internalNote.trim() : ''
    setConditions(newData)
    setEditingCondition(null)
    setLoading(true)
    await updateLoanSubmission({ update: [newData[index]] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onUpdateConditionValue = async (key: string, value: boolean, no: number) => {
    const newData = cloneDeep(conditions)
    const item = newData.find((condition) => condition.no == no)
    if (!item) return
    if (conditionReviewNos[no] && key === 'showCleared') {
      if (!item.cleared) {
        if (reviewStatus[conditionReviewNos[no].key]?.status === 1) {
        } else {
          return toast(`'${conditionReviewNos[no].label}' task need to be completed before clear!`, { type: 'error' })
        }
      }
    }

    item[key] = value
    setConditions(newData)

    if (key.startsWith('show')) return
    if (key === 'exceptionStatus') return
    setLoading(true)
    await updateLoanSubmission({ update: [item] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onSetRequested = async (value: boolean, no: number) => {
    const newData = cloneDeep(conditions)
    const item = newData.find((condition) => condition.no == no)
    if (!item) return
    item.requested = value
    item.showRequested = false
    item.requestedDate = formatDate('now')
    item.requestedBy = profile.name
    setConditions(newData)
    setLoading(true)
    await updateLoanSubmission({ update: [item] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onSetCleared = async (value: boolean, no: number) => {
    const newData = cloneDeep(conditions)
    const item = newData.find((condition) => condition.no == no)
    if (!item) return
    item.cleared = value
    item.showCleared = false
    item.clearedDate = formatDate('now')
    item.clearedBy = profile.name
    setConditions(newData)
    setLoading(true)
    await updateLoanSubmission({ update: [item] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onSetException = async (value: boolean, no: number) => {
    const newData = cloneDeep(conditions)
    const item = newData.find((condition) => condition.no == no)
    if (!item) return
    item.exception = value
    item.exceptionDate = formatDate('now')
    if (value) item.exceptionBy = profile.name
    else delete item.exceptionStatus
    item.showException = false

    setConditions(newData)
    setLoading(true)
    await updateLoanSubmission({ update: [item] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  /**
   *
   * @param event
   * @param conditionNo
   * @returns
   */
  const onUploadNewfile = (event: React.ChangeEvent<HTMLInputElement>, conditionNo: number) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    uploadDocumentFiles(arrFile, conditionNo)
  }

  const uploadDocumentFiles = (files: File[], conditionNo: number): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: `loan/${loanNumber}/document`,
      }

      let newConditions: any[]
      let item: any
      if (currentTemplate != 0) {
        newConditions = cloneDeep(conditions)
        item = newConditions.find(({ no }) => conditionNo == no)
        item.loadingDoc = true
        setConditions(newConditions)
      } else setLoading(true)

      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        if (acceptedFileTypes.includes(ext)) {
          acceptedFiles.push(file)
        } else {
          toast(`${name} is not ${acceptedFileTypes.join(', ')}`, { type: 'error' })
        }
      })

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        if (currentTemplate != 0) {
          item.loadingDoc = false
          setConditions(newConditions)
        }

        const newData = cloneDeep(documents)
        const newDocs: LoanSubmissionDocument[] = []
        keys.forEach((key, index) => {
          newDocs.push({
            conditionNo: conditionNo,
            name: acceptedFiles[index].name,
            key,
            status: 'Not Reviewed',
            category: '',
            createdAt: Date.now(),
            generated: false,
            createdBy: profile.email,
          })
        })
        newData.push(...newDocs)
        setDocuments(newData)
        setLoading(true)
        await updateLoanSubmission({}, { update: newDocs })
        setSubmissionUpdatedAt(Date.now())
        setLoading(false)

        resolve({
          documents: newData,
          newDocuments: newDocs,
        })
      })
    })
  }

  const onChooseDocumentForCondition = (conditionNo: number) => {
    setEditingConditionNo(conditionNo)
  }

  const onEnableDocumentCheckForCondition = (conditionNo: number) => {
    const newStates = cloneDeep(isSelectDocuments)
    if (newStates[conditionNo]) delete newStates[conditionNo]
    else newStates[conditionNo] = {}
    setSelectDocuments(newStates)
  }

  const onRemoveCheckedDocument = async (conditionNo: number) => {
    if (!isSelectDocuments[conditionNo]) return
    const selectedDocuments = isSelectDocuments[conditionNo]
    const docKeys = Object.keys(selectedDocuments).filter((docKey) => selectedDocuments[docKey])
    if (!docKeys.length) return

    let deleteButtons: any = {
      temparary: `Remove from Condition ${conditionNo}`,
    }
    if (canDeleteDocumentPermanently) {
      deleteButtons['permanently'] = {
        title: 'Permanently Delete',
        color: 'red',
      }
    }
    const result = await multiConfirm('Do you want to remove these documents?', {
      ...deleteButtons,
    })
    if (!result) return

    const newData = cloneDeep(documents)
    let indexes = docKeys
      .map((key) => newData.findIndex((doc) => doc.key == key))
      .filter((v) => v != -1)
      .sort((a, b) => a - b)
    const isRemoveCompletely = result == 'permanently'

    if (isRemoveCompletely) {
      indexes = indexes.filter((index) => !newData[index].generated)
      if (!indexes.length) return
      const content = (
        <div className="text-gray-900 mb-4 text-md">
          Are you sure want to delete this documents permanently?
          <div className="text-gray-800 flex flex-col text-left text-sm mt-4">
            {indexes.map((index) => (
              <span key={newData[index].key}>- {newData[index] && newData[index].name}</span>
            ))}
          </div>
        </div>
      )
      const result = await confirm(content)
      if (!result) return
    }

    setLoading(true)

    if (isRemoveCompletely) {
      const removedDocs: Record<string, any>[] = []
      indexes.reverse().forEach((index) => {
        if (newData[index].generated) return
        const items = newData.splice(index, 1)
        removedDocs.push(...items)
      })
      await updateLoanSubmission({}, { delete: removedDocs })
    } else {
      const updatedData = indexes.map((index) => {
        newData[index].conditionNo = 0
        return newData[index]
      })
      await updateLoanSubmission({}, { update: updatedData })
    }

    setDocuments(newData)
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const setDocumentCheck = (conditionNo: number, documentKey: string, value: boolean) => {
    const newStates = cloneDeep(isSelectDocuments)
    if (!newStates[conditionNo]) newStates[conditionNo] = {}
    newStates[conditionNo][documentKey] = value
    setSelectDocuments(newStates)
  }

  const onUpdateConditionDocuments = async (keys: Array<string>) => {
    const updatedDocs: Array<any> = []
    documents.forEach((doc) => {
      const isChecked = keys.includes(doc.key)
      if (isChecked && doc.conditionNo == editingConditionNo) return
      if (!isChecked && doc.conditionNo != editingConditionNo) return

      doc.conditionNo = isChecked ? editingConditionNo : 0
      updatedDocs.push(doc)
    })
    setEditingConditionNo(0)
    setLoading(true)
    await updateLoanSubmission({}, { update: updatedDocs })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onCloseChoosingDocuments = () => {
    setEditingConditionNo(0)
  }

  const onUpdateDocumentProp = async (key: string, value: string, conditionNo: number, documentKey: string) => {
    const newData = cloneDeep(documents)
    const item = newData.find((doc) => (!conditionNo || doc.conditionNo == conditionNo) && doc.key == documentKey)
    if (!item) return
    if (key == 'name') value = value + item.name.substr(-4)
    ;(item as any)[key] = value
    setDocuments(newData)
    setLoading(true)
    await updateLoanSubmission({}, { update: [item] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onRemoveDocument = async (document: any, isRemoveCompletely: boolean = false) => {
    const content = (
      <div className="text-gray-900 mb-4 text-md">
        {isRemoveCompletely
          ? 'Are you sure want to delete this document permanently?'
          : 'Are you sure want to delete this document?'}
        <div className="text-gray-800 flex flex-col text-left text-sm">
          {document.conditionNo ? <span>Condition No: {document.conditionNo}</span> : null}
          <span>Document Name: {document.name}</span>
          <span>Uploaded Date: {formatTime(new Date(document.createdAt))}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(documents)
    const index = newData.findIndex((doc) => doc.conditionNo == document.conditionNo && doc.key == document.key)
    if (index == -1) return
    if (isRemoveCompletely) {
      if (document.generated) return
      const removedDocs = newData.splice(index, 1)
      setDocuments(newData)
      setLoading(true)
      await updateLoanSubmission({}, { delete: removedDocs })
      setSubmissionUpdatedAt(Date.now())
      setLoading(false)
    } else {
      newData[index].conditionNo = 0
      setDocuments(newData)
      setLoading(true)
      await updateLoanSubmission({}, { update: [newData[index]] })
      setSubmissionUpdatedAt(Date.now())
      setLoading(false)
    }
  }

  const onRemoveDocuments = async (docKeys: string[]) => {
    const newData = cloneDeep(documents)
    const indexes = docKeys
      .map((key) => newData.findIndex((doc) => doc.key == key))
      .filter((v) => v != -1)
      .sort((a, b) => a - b)
    const removedDocs: Record<string, any>[] = []
    indexes.reverse().forEach((index) => {
      if (newData[index].generated) return
      const items = newData.splice(index, 1)
      removedDocs.push(...items)
    })
    setLoading(true)
    await updateLoanSubmission({}, { delete: removedDocs })

    setDocuments(newData)
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onOpenDocument = async (document: any) => {
    openS3Document(document.key, true)
  }

  const onOpenDocuments = (condNo: number) => {
    const properDocs = documents.filter(
      ({ conditionNo, name }) => name.toLowerCase().endsWith('.pdf') && conditionNo == condNo,
    )
    properDocs.forEach((doc) => onOpenDocument(doc))
  }

  const onGeneratePdf = async (pdfType: LoanSubmissionPdfType) => {
    setAction(`Pdf-${pdfType}`)
    const json = await loanSubmissionPdfData()
    const data: Blob = await getLoanSubmissionPdf({ json, pdfType })
    downloadFile(`${COMPANY_NAME_FC} Conditions - ${loanDetail.byteproFileName}.pdf`, data)
    setAction('')
  }

  const onSendPdfEmail = async (pdfType: LoanSubmissionPdfType) => {
    setSendPdfEmailType(pdfType)
  }

  const onUpdateDocuments = async (newDocs: Array<any>, updatedDoc: any) => {
    setDocuments(newDocs)
    setLoading(true)
    await updateLoanSubmission({}, { update: [updatedDoc] })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onAddDocuments = async (docs: DocumentFile[]) => {
    const newDocs = cloneDeep(documents)
    const addingDocs: LoanSubmissionDocument[] = docs.map((doc) => ({
      conditionNo: 0,
      name: doc.name,
      key: doc.fileKey,
      status: doc.status,
      category: doc.category,
      createdAt: doc.createdAt,
      generated: false,
      createdBy: profile.email,
    }))
    newDocs.push(...addingDocs)
    setDocuments(newDocs)
    setLoading(true)
    await updateLoanSubmission({}, { update: addingDocs })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onDropDocument = async (document: any, conditionNo: number) => {
    let newData = cloneDeep(documents)
    let newDocuments: any[] = []

    setLoading(true)
    if (document.files) {
      const { newDocuments: newDocs, documents } = await uploadDocumentFiles(document.files, conditionNo)
      newData = cloneDeep(documents)
      newDocuments = cloneDeep(newDocs)
    } else newDocuments = [document]

    const updatedData = newDocuments
      .map((document) => {
        const index = newData.findIndex((doc) => doc.key == document.key)
        if (index == -1) return null
        if (conditionNo == newData[index].conditionNo) return null

        newData[index].conditionNo = conditionNo
        return newData[index]
      })
      .filter((data) => !!data)
    if (updatedData.length == 0) {
      setLoading(false)
      return
    }

    setDocuments(newData)
    await updateLoanSubmission({}, { update: updatedData })
    setSubmissionUpdatedAt(Date.now())
    setLoading(false)
  }

  const onSortDocument = (conditionNo: number, key: string, order: number) => {
    const newSorts = cloneDeep(documentsSortBy)
    newSorts[conditionNo] = {
      key,
      order,
    }
    setDocumentsSortBy(newSorts)
  }

  const additionalDescription = (condition: LoanSubmissionCondition) => {
    if (condition.no == 3359) {
      return (
        <div className="text-shade-blue hover:underline cursor-pointer">
          <MedianPriceLink address={loanDetail.propertyAddress} />
        </div>
      )
    }
    return null
  }

  const renderDescription = (condition: LoanSubmissionCondition) => {
    const { name, brokerDescription, description, note, internalNote, borrowerNote } = condition
    let _description = description
    if (brokerProfile && brokerDescription?.length) _description = brokerDescription

    return (
      <div>
        <div className="flex flex-wrap items-center mb-3">
          <b className="mr-3">
            <span>Description:</span>
          </b>
          {brokerProfile ? (
            <p>{name}</p>
          ) : (
            <Tooltip message={brokerDescription || ''}>
              <p className="cursor-pointer">{name}</p>
            </Tooltip>
          )}
        </div>
        <pre className="mb-3 whitespace-pre-wrap">
          <div dangerouslySetInnerHTML={{ __html: _description }}></div>
        </pre>

        {additionalDescription(condition)}

        {note && (
          <>
            <div className="flex flex-wrap items-center">
              <b className="mr-3">Note: </b>
            </div>
            <pre className="w-full border-t bg-gray-100 p-1 rounded whitespace-pre-wrap">
              <div dangerouslySetInnerHTML={{ __html: note }}></div>
            </pre>
          </>
        )}

        {borrowerNote && (
          <>
            <div className="flex flex-wrap items-center mt-3">
              <b className="mr-3">Borrower Note: </b>
            </div>
            <pre className="w-full border-t bg-gray-100 p-1 rounded whitespace-pre-wrap">
              <div dangerouslySetInnerHTML={{ __html: borrowerNote }}></div>
            </pre>
          </>
        )}

        {!brokerProfile && internalNote && (
          <>
            <div className="flex flex-wrap items-center mt-3">
              <b className="mr-3">Internal Note: </b>
            </div>
            <pre className="w-full border-t bg-gray-100 p-1 rounded whitespace-pre-wrap">
              <div dangerouslySetInnerHTML={{ __html: internalNote }}></div>
            </pre>
          </>
        )}
      </div>
    )
  }

  const renderOriginDocument = (key: number) => {
    const brokerConditionNos = brokerConditions.map((c) => c.no).filter((no) => !!no)
    const properDocs = documents.filter(({ conditionNo }) =>
      key ? conditionNo == key : !conditionNo || !brokerConditionNos.includes(conditionNo),
    )
    let trashView = true
    if (brokerProfile && loanDetail.submitToLoanSetup) trashView = false
    return properDocs.map((document, index: number) => (
      <SourceBox item={document} type="document" key={document.key}>
        <tr className="hover:bg-slate-100" key={index}>
          <td className="pl-10">
            <PlainInput
              value={document.name.substr(0, document.name.length - 4)}
              content={document.name}
              onChange={(newName: string) => onUpdateDocumentProp('name', newName, 0, document.key)}
            />
          </td>
          <td className="px-2 w-52 py-1">
            <Select
              id={`document-category-${document.conditionNo}`}
              size={3}
              className="-mb-4"
              options={docCategory}
              value={document.category}
              onChange={(value) => onUpdateDocumentProp('category', value, 0, document.key)}
            />
          </td>
          <td className="px-2 w-40">{formatTime(document.createdAt)}</td>
          <td className="px-2 w-20">
            <button
              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
              onClick={() => onOpenDocument(document)}
            >
              <EyeIcon className="w-4 h-4" />
            </button>
            {trashView && (
              <button
                className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                onClick={() => onRemoveDocument(document, true)}
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            )}
          </td>
        </tr>
      </SourceBox>
    ))
  }

  const reviewRequest = async (key: string) => {
    setAction(key)
    const res = await postReviewRequest(key)
    setAction('')
    if (res.success) {
      let temp = cloneDeep(reviewStatus)
      temp[key] = res.data
      setReviewStatus(temp)
    }
  }

  const brokerProfile = useMemo(() => {
    return !hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')
  }, [])

  const onDownloadSSAPdf = async (borrowerSeperator: string, fullName: string) => {
    const data: Blob = await getSSAPdf(borrowerSeperator)
    downloadFile(`SSA89 (${fullName}).pdf`, data)
  }

  const renderReviewAction = (no: number) => {
    if (no === actionConditionNos.SSN) {
      return (
        <div className="my-2 text-[13px] font-variation-settings-600 flex gap-2 flex-col">
          <div
            className="ml-1 flex w-fit items-center gap-1 text-shade-blue hover:underline cursor-pointer"
            onClick={() => onDownloadSSAPdf('borrower', `${borrower.firstName} ${borrower.lastName}`)}
          >
            Borrower SSA89 PDF
            <ArrowDownTrayIcon className="h-[14px] w-[14px]" aria-hidden="true" />
          </div>
          {loanDetail.includeCoborrower && (
            <div
              className="ml-1 flex w-fit items-center gap-1 text-shade-blue hover:underline cursor-pointer"
              onClick={() => onDownloadSSAPdf('coBorrower', `${coBorrower.firstName} ${coBorrower.lastName}`)}
            >
              CoBorrower SSA89 PDF
              <ArrowDownTrayIcon className="h-[14px] w-[14px]" aria-hidden="true" />
            </div>
          )}
        </div>
      )
    }
    if (currentTemplate === 0) return
    if (no === actionConditionNos.FLOOD_DETERMINATION) {
      return (
        <div className="my-2 flex">
          <Link to={`/national_flood/${loanDetail.loanNumber}`} target="_blank">
            <span className="flex gap-1 items-center cursor-pointer text-shade-blue hover:underline font-variation-settings-600">
              <span>Submit Order</span>
              <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
            </span>
          </Link>
        </div>
      )
    }
    if (conditionReviewNos[no]) {
      const { key, label } = conditionReviewNos[no]
      const result = reviewStatus[key]
      return (
        <div className="my-2">
          {result?.status !== 0 && !brokerProfile && (
            <div className="">
              <Button
                loading={action === key}
                color="gray"
                className="py-[4px]"
                size={'[13px]'}
                onClick={() => reviewRequest(key)}
              >
                {label}
              </Button>
            </div>
          )}
          {result && (
            <div className="table overflow-auto">
              <table>
                <tbody className="text-[12px]">
                  <tr>
                    <td className="border border-gray-400 px-1">Requested Date</td>
                    <td className="border border-gray-400 px-1">{formatTime(result.requestDate)}</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-400 px-1">By</td>
                    <td className="border border-gray-400 px-1">{result.requestBy}</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-400 px-1">Status</td>
                    <td className="border border-gray-400 px-1">{result.status === 0 ? 'Pending' : 'Completed'}</td>
                  </tr>
                  {result.status === 1 && (
                    <tr>
                      <td className="border border-gray-400 px-1">Completed Date/By</td>
                      <td className="border border-gray-400 px-1">
                        {formatTime(result.completeDate)}
                        <br />
                        {result.completeBy}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="border border-gray-400 px-1">Notes</td>
                    <td className="border border-gray-400 px-1">{result.note}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      )
    }
  }

  const showConditionHistory = (no: number) => {
    const options = {
      table: 'LoanSubmission',
      field: `Conditon No: ${no}`,
      keys: {
        field: no,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    openAuditLog(options)
  }

  const renderOriginalTemplate = () => {
    let index = 0
    const content = [1, 0].map((required) => (
      <React.Fragment key={required}>
        <tr className="bg-gray-200 font-bold">
          <td colSpan={4} className="px-6 py-2">
            <span>{required ? 'Required' : 'Optional'}</span>
          </td>
        </tr>
        {brokerConditions
          .filter((cond) => cond.required == !!required)
          .map((condition) => {
            let bgCn = index % 2 ? 'bg-gray-50' : ''
            const key = condition.no
            index += 1
            return (
              <React.Fragment key={key}>
                <tr className={bgCn}>
                  <td colSpan={4} className="border-b px-1">
                    <TargetBox
                      types={['document', NativeTypes.FILE]}
                      onDrop={(document: any) => onDropDocument(document, key)}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr className="">
                            <td className="px-6 py-1" colSpan={3}>
                              <div className="flex items-center">
                                <span className="mr-3">{index}.</span>{' '}
                                <span className="text-[15px]">{condition.name}</span>
                                <button
                                  className="text-gray-700 p-1 hover-shadow1 cursor-pointer ml-2"
                                  onClick={() => onExpandOriginItem(`${key}`)}
                                >
                                  <QuestionMarkCircleIcon className="w-4 h-4"></QuestionMarkCircleIcon>
                                </button>
                                {renderReviewAction(condition.no)}
                              </div>
                            </td>
                            <td className="px-6 py-1 w-20">
                              <label
                                className="text-gray-900 p-1 hover-shadow1 cursor-pointer inline-block"
                                htmlFor={`document-file-${key}`}
                              >
                                <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                              </label>

                              <input
                                className="hidden"
                                id={`document-file-${key}`}
                                type="file"
                                accept=".pdf,.xml"
                                multiple
                                required
                                onChange={(event) => onUploadNewfile(event, key)}
                              />
                            </td>
                          </tr>
                          {originItemExpands[key] ? (
                            <tr>
                              <td className="pl-12 py-1 text-gray-600" colSpan={3}>
                                <span
                                  dangerouslySetInnerHTML={{ __html: replaceNextLineToBr(condition.description) }}
                                />
                              </td>
                              <td></td>
                            </tr>
                          ) : null}
                          {renderOriginDocument(key)}
                        </tbody>
                      </table>
                    </TargetBox>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
      </React.Fragment>
    ))

    return (
      <div className="relative parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
        <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
          <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
            <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
            <span>Drag and drop files.</span>
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">Document/File Name</th>
              <th className="px-6 py-3 w-52">Category</th>
              <th className="px-6 py-3 w-40">Date Uploaded</th>
              <th className="px-6 py-3 w-20">Actions</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    )
  }

  const renderCondition = (category: string) => {
    let renderConditions = conditions
      .filter((condition) => condition.category === category)
      .sort((a, b) => {
        if (a.cleared && !b.cleared) return 1
        if (!a.cleared && b.cleared) return -1
        if (a.intext && !b.intext) return 1
        if (!a.intext && b.intext) return -1
        return conditionOrder.indexOf(a.no) - conditionOrder.indexOf(b.no)
      })

    if (conditionSortBy) {
      const sortBy = conditionSortBy.toLowerCase()
      renderConditions = renderConditions.sort((a: any, b: any) => {
        const aV = a[sortBy]
        const bV = b[sortBy]
        if (aV > bV) return 1
        if (aV == bV) return 0
        return -1
      })
    }
    return (
      <div className={`flex flex-col gap-2 ${categoryOpenStatus[category] ? 'border p-1' : 'hidden p-1'}`}>
        {renderConditions.map((condition: LoanSubmissionCondition) => {
          const isEditing = editingCondition && condition.no == editingCondition.no
          const documentSortBy = documentsSortBy[condition.no] || {}
          let properDocs = documents.filter(({ conditionNo }) => conditionNo == condition.no)
          if (documentSortBy.key) {
            properDocs = sortByKey(properDocs, documentSortBy.key)
            if (documentSortBy.order == -1) properDocs = properDocs.reverse()
          }

          if (filterCleared && condition.cleared) return null
          if (respFilter[condition.responsibility] === false) return null

          const showCheckbox = isSelectDocuments[condition.no]

          let notReviewedDocs = false
          properDocs.map((doc) => {
            if (doc.status === 'Not Reviewed' || !doc.status) notReviewedDocs = true
          })

          return (
            <TargetBox
              types={['document', NativeTypes.FILE]}
              onDrop={(document: any) => onDropDocument(document, condition.no)}
              key={condition.no}
            >
              <div
                className={`relative group p-3 py-5 border-b ${
                  condition.cleared
                    ? 'bg-green-100 shadow1'
                    : condition.intext
                    ? 'bg-blue-100 shadow1'
                    : condition.requested
                    ? 'bg-yellow-50 shadow1'
                    : 'hover:bg-slate-100 hover-shadow1 shadow'
                }`}
              >
                <div className="grid grid-cols-12 gap-x-2 mb-3 text-[13px] overflow-visible gap-y-4">
                  <div className="md:col-span-3 sm:col-span-6 col-span-12 grid grid-cols-2 h-fit">
                    <p>No:</p>
                    <p className="font-bold">{condition.no}</p>

                    <p>Type:</p>
                    {isEditing ? (
                      <Select
                        id="type"
                        className="-mb-4"
                        size={3}
                        options={condConstant.types}
                        value={editingCondition.type}
                        onChange={(value) => setEditingConditionValue('type', value)}
                      />
                    ) : (
                      <p className="font-bold text-[12px]">{condition.type}</p>
                    )}

                    <p>Class:</p>
                    {isEditing ? (
                      <Select
                        id="class"
                        className="-mb-4"
                        size={3}
                        options={condConstant.classes}
                        value={editingCondition.class}
                        onChange={(value) => setEditingConditionValue('class', value)}
                      />
                    ) : (
                      <p className="font-bold text-[12px]">{condition.class}</p>
                    )}

                    <p>Responsibility:</p>
                    {isEditing ? (
                      <Select
                        id="responsibility"
                        className="-mb-4"
                        size={3}
                        options={condConstant.respon}
                        value={editingCondition.responsibility}
                        onChange={(value) => setEditingConditionValue('responsibility', value)}
                      />
                    ) : (
                      <p className="font-bold text-[12px]">{condition.responsibility}</p>
                    )}

                    {isEditing && (
                      <>
                        <p>Category:</p>

                        <Select
                          id="category"
                          className="-mb-4"
                          size={3}
                          options={categories}
                          value={editingCondition.category}
                          onChange={(value) => setEditingConditionValue('category', value)}
                        />
                      </>
                    )}
                  </div>
                  <div className="md:col-span-6 sm:col-span-6 col-span-12">
                    {isEditing ? (
                      <>
                        <TextArea
                          title="Condition Name"
                          className="mb-2"
                          rows={0}
                          value={editingCondition.name}
                          onChange={(value) => setEditingConditionValue('name', value)}
                        />
                        <TextArea
                          title="UW Description"
                          className="mb-2"
                          rows={0}
                          value={editingCondition.description}
                          onChange={(value) => setEditingConditionValue('description', value)}
                        />
                        <TextArea
                          title="Broker Description"
                          className="mb-2"
                          rows={0}
                          value={editingCondition.brokerDescription}
                          onChange={(value) => setEditingConditionValue('brokerDescription', value)}
                        />
                        <TextArea
                          title="Note"
                          rows={3}
                          value={editingCondition.note}
                          onChange={(value) => setEditingConditionValue('note', value)}
                        />
                        {!brokerProfile && (
                          <TextArea
                            title="Internal Note"
                            rows={3}
                            value={editingCondition.internalNote}
                            onChange={(value) => setEditingConditionValue('internalNote', value)}
                          />
                        )}
                      </>
                    ) : (
                      renderDescription(condition)
                    )}
                  </div>
                  <div className="relative z-10 md:col-span-3 sm:col-span-6 col-span-12 text-[13px]">
                    {!brokerProfile && (
                      <Checkbox
                        color="gray"
                        size={3}
                        title="Int/Ext"
                        id={`intext-${condition.no}`}
                        value={condition.intext}
                        onChange={(value) => onUpdateConditionValue('intext', value, condition.no)}
                      />
                    )}

                    <div className="relative overflow-visible">
                      <div className="flex flex-wrap">
                        <div className="flex-[1]">
                          <Checkbox
                            color="gray"
                            size={3}
                            title="Requested:"
                            id={`requested-${condition.no}`}
                            value={condition.requested}
                            disabled={brokerProfile}
                            checked={condition.requested || false}
                            onClick={() => onUpdateConditionValue('showRequested', true, condition.no)}
                          />
                        </div>
                        {condition.requested && (
                          <div className="flex-[2]">
                            <p className="font-bold">{`By ${condition.requestedBy}: ${condition.requestedDate}`}</p>
                          </div>
                        )}
                      </div>
                      {condition.showRequested && (
                        <div className="absolute shadow1 bg-gray-50 rounded z-10 p-3 pb-1 border">
                          <p>
                            Are you sure want to Mark as <b>{condition.requested ? 'Not' : ''} Requested</b>?
                          </p>
                          <div className="flex justify-end">
                            <button
                              className="hover:underline p-1 cursor-pointer text-shade-blue font-bold"
                              onClick={() => onSetRequested(!condition.requested, condition.no)}
                            >
                              Confirm
                            </button>
                            <button
                              className="hover:underline p-1 cursor-pointer text-red-800 font-bold"
                              onClick={() => onUpdateConditionValue('showRequested', false, condition.no)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="relative overflow-visible">
                      <div className="flex flex-wrap">
                        <div className="flex-[1]">
                          <Checkbox
                            color="gray"
                            size={3}
                            title="Cleared:"
                            id={`cleared-${condition.no}`}
                            value={condition.cleared}
                            disabled={brokerProfile}
                            checked={condition.cleared || false}
                            onClick={() => {
                              if (notReviewedDocs) {
                                return toast('Some documents have not been reviewed yet.', { type: 'error' })
                              }
                              onUpdateConditionValue('showCleared', true, condition.no)
                            }}
                          />
                        </div>
                        {condition.cleared && (
                          <div className="flex-[2]">
                            <p className="font-bold">{`By ${condition.clearedBy}: ${condition.clearedDate}`}</p>
                          </div>
                        )}
                      </div>
                      {condition.showCleared && (
                        <div className="absolute shadow1 bg-gray-50 rounded z-10 p-3 pb-1 border">
                          <p>
                            Are you sure want to Mark as <b>{condition.cleared ? 'Not' : ''} Cleared</b>?
                          </p>
                          <div className="flex justify-end">
                            <button
                              className="hover:underline p-1 cursor-pointer text-shade-blue font-bold"
                              onClick={() => onSetCleared(!condition.cleared, condition.no)}
                            >
                              Confirm
                            </button>
                            <button
                              className="hover:underline p-1 cursor-pointer text-red-800 font-bold"
                              onClick={() => onUpdateConditionValue('showCleared', false, condition.no)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="relative overflow-visible">
                      <div className="flex flex-wrap">
                        <div className="flex-[1]">
                          <Checkbox
                            color="gray"
                            size={3}
                            title="Exception:"
                            id={`exception-${condition.no}`}
                            value={condition.exception}
                            disabled={brokerProfile}
                            checked={condition.exception || false}
                            onClick={() => onUpdateConditionValue('showException', true, condition.no)}
                          />
                        </div>
                        {condition.exception && (
                          <div className="flex-[2]">
                            <p className="font-bold">{`By ${condition.exceptionBy}: ${condition.exceptionDate}`}</p>
                          </div>
                        )}
                      </div>
                      {condition.showException && (
                        <div className="absolute bg-gray-50 rounded z-10 p-3 pb-1 shadow1 border">
                          <p className="mb-2">
                            Are you sure want to Mark as <b>{condition.exception ? 'Not' : ''} Exception Requested</b>?
                          </p>

                          <Select
                            id="category"
                            size={3}
                            options={exceptionStatus}
                            value={`${condition.exceptionStatus}`}
                            disabled={condition.exception}
                            onChange={(value) => onUpdateConditionValue('exceptionStatus', value, condition.no)}
                          />

                          <div className="flex justify-end">
                            <button
                              className="hover:underline p-1 cursor-pointer text-shade-blue font-bold"
                              onClick={() => onSetException(!condition.exception, condition.no)}
                            >
                              Confirm
                            </button>
                            <button
                              className="hover:underline p-1 cursor-pointer text-red-800 font-bold"
                              onClick={() => onUpdateConditionValue('showException', false, condition.no)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {renderReviewAction(condition.no)}
                  </div>
                  {!isEditing && canEditCondition && (
                    <div className="absolute top-1 right-1 hidden group-hover:flex">
                      <div className="relative z-10 flex gap-2">
                        <button
                          className="hover-shadow1 p-1 cursor-pointer text-shade-blue"
                          onClick={() => onEditCondition(condition)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </button>
                        <button
                          className="hover-shadow1 p-1 cursor-pointer"
                          onClick={() => showConditionHistory(condition.no)}
                        >
                          <ClockIcon className="w-4 h-4"></ClockIcon>
                        </button>
                        <button
                          className="hover-shadow1 p-1 cursor-pointer text-red-800"
                          onClick={() => onRemoveCondition(condition)}
                        >
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`relative w-full rounded p-2 text-sm text-left ${
                    notReviewedDocs ? 'bg-red-50' : 'bg-gray-50'
                  }`}
                >
                  {!isEditing && (
                    <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
                      <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
                        <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                        <span>Drag and drop files.</span>
                      </div>
                    </div>
                  )}
                  <div className="flex border-b pb-1">
                    <p className="flex-auto">Documents ({properDocs.length})</p>
                    {condition.loadingDoc && (
                      <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />
                    )}
                    <button
                      className={`text-gray-700 p-1 hover-shadow1 cursor-pointer ${
                        showCheckbox ? 'rounded bg-gray-400 text-white' : ''
                      }`}
                      onClick={() => onOpenDocuments(condition.no)}
                    >
                      <EyeIcon className="w-4 h-4"></EyeIcon>
                    </button>
                    {canDeleteDocumentPermanently && (
                      <button
                        className={`text-gray-700 p-1 hover-shadow1 cursor-pointer ${
                          showCheckbox ? 'rounded bg-gray-400 text-white' : ''
                        } ml-2`}
                        onClick={() => onEnableDocumentCheckForCondition(condition.no)}
                      >
                        <DocumentCheckIcon className="w-4 h-4"></DocumentCheckIcon>
                      </button>
                    )}
                    {showCheckbox && (
                      <button
                        className="text-red-800 p-1 hover-shadow1 cursor-pointer ml-2"
                        onClick={() => onRemoveCheckedDocument(condition.no)}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </button>
                    )}
                    {canDeleteDocumentPermanently && (
                      <button
                        className="text-gray-700 p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onChooseDocumentForCondition(condition.no)}
                      >
                        <ListBulletIcon className="w-4 h-4"></ListBulletIcon>
                      </button>
                    )}
                    <label
                      className="text-gray-700 p-1 hover-shadow1 cursor-pointer"
                      htmlFor={`condition-file-${condition.no}`}
                    >
                      <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                    </label>

                    <input
                      className="hidden"
                      id={`condition-file-${condition.no}`}
                      type="file"
                      accept=".pdf,.xml"
                      multiple
                      required
                      onChange={(event) => onUploadNewfile(event, condition.no)}
                    />
                  </div>
                  <div className="overflow-auto relative">
                    <table className="w-full text-[13px]">
                      <thead className="font-medium text-gray-600">
                        <tr>
                          {showCheckbox && <th className="px-1 py-1 w-4"></th>}
                          <th className="px-2 py-1">Document Name</th>
                          {renderHeader({
                            title: 'Status',
                            index: 0,
                            key: 'status',
                            sortable: true,
                            sortOrder: documentSortBy.key == 'status' ? documentSortBy.order : 0,
                            onSort: (key: string, order: number) => onSortDocument(condition.no, key, order),
                          })}
                          {renderHeader({
                            title: 'Category',
                            index: 1,
                            key: 'category',
                            sortable: true,
                            sortOrder: documentSortBy.key == 'category' ? documentSortBy.order : 0,
                            onSort: (key: string, order: number) => onSortDocument(condition.no, key, order),
                          })}
                          <th className="px-2">Uploaded By</th>
                          <th className="px-2">Uploaded Date</th>
                          <th className="px-2">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-900 text-[14px]">
                        {properDocs.map((document: any, index: number) => (
                          <tr className="hover:bg-slate-100" key={`${index}-${document.key}`}>
                            {showCheckbox && (
                              <td className="py-1 px-1">
                                <Checkbox
                                  title=""
                                  id={document.key}
                                  checked={isSelectDocuments[condition.no][document.key]}
                                  onChange={(value) => {
                                    setDocumentCheck(condition.no, document.key, value)
                                  }}
                                />
                              </td>
                            )}
                            <td className="px-2">
                              {brokerProfile && loanDetail.submitToLoanSetup === 1 ? (
                                <span className="italic text-[13.5px]">{document.name}</span>
                              ) : (
                                <PlainInput
                                  value={document.name.substr(0, document.name.length - 4)}
                                  content={document.name}
                                  onChange={(newName: string) =>
                                    onUpdateDocumentProp('name', newName, condition.no, document.key)
                                  }
                                />
                              )}
                            </td>
                            <td
                              className={`px-2 w-32 ${
                                document.status === 'Not Reviewed' || !document.status
                                  ? 'border-l-[3px] border-red-500'
                                  : ''
                              }`}
                            >
                              {brokerProfile ? (
                                <span className="italic text-[13.5px]">{document.status}</span>
                              ) : (
                                <Select
                                  id={`document-status-${condition.no}-${index}`}
                                  size={3}
                                  className="-mb-4"
                                  options={docStatus}
                                  value={document.status}
                                  onChange={(value) =>
                                    onUpdateDocumentProp('status', value, condition.no, document.key)
                                  }
                                />
                              )}
                            </td>
                            <td className="px-2 w-52">
                              {brokerProfile && loanDetail.submitToLoanSetup === 1 ? (
                                <span className="italic text-[13.5px]">{document.category}</span>
                              ) : (
                                <Select
                                  id={`document-category-${condition.no}-${index}`}
                                  size={3}
                                  className="-mb-4"
                                  options={docCategory}
                                  value={document.category}
                                  onChange={(value) =>
                                    onUpdateDocumentProp('category', value, condition.no, document.key)
                                  }
                                />
                              )}
                            </td>
                            <td className="px-2 w-36">{document.createdBy || '-'}</td>
                            <td className="px-2 w-36">{formatTime(new Date(document.createdAt))}</td>
                            <td className="w-24">
                              {!brokerProfile && (
                                <button
                                  className="text-gray-600 p-1 hover-shadow1 cursor-pointer"
                                  onClick={() => setHistoryDocument(document)}
                                >
                                  <ClockIcon className="w-4 h-4" />
                                </button>
                              )}
                              <button
                                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onOpenDocument(document)}
                              >
                                <EyeIcon className="w-4 h-4" />
                              </button>
                              {!canDeleteDocumentPermanently ? null : (
                                <button
                                  className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                                  onClick={() => onRemoveDocument(document, false)}
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {isEditing && (
                  <div className="flex w-full justify-end mt-4 gap-4">
                    <button
                      className="text-[14px] hover:underline font-bold text-red-500"
                      onClick={onCancelEditingCondition}
                    >
                      Cancel
                    </button>
                    <button
                      className="text-[14px] px-2 py-1 bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded"
                      onClick={onUpdateEditingCondition}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </TargetBox>
          )
        })}
      </div>
    )
  }

  const onExpandOriginItem = (key: string) => {
    const newData = cloneDeep(originItemExpands)
    if (newData[key]) delete newData[key]
    else newData[key] = true
    setOriginItemExpands(newData)
  }

  const renderPdfActions = () => {
    let label = 'Generate Approval'
    if (loanDetail.loanStatus === 'suspended') label = 'Generate Suspense'
    if (brokerProfile) return null
    const afterApprovedStatus = loanStatusConstants.suspendedStatus.indexOf(loanDetail.loanStatus) !== -1
    return (
      <div className="p-3 shadow w-96">
        <div className="border-b mb-4 text-gray-800 font-bold text-[18px] flex justify-between items-center">
          <span>Documents</span>
          <span className="text-shade-blue">
            <ArrowDownTrayIcon className="w-5 h-5"></ArrowDownTrayIcon>
          </span>
        </div>
        <div className="flex gap-1 mb-2 items-center">
          <Button
            className="w-full"
            color="gray"
            loading={action === `Pdf-${LoanSubmissionPdfType.APPROVAL}`}
            onClick={() => onGeneratePdf(LoanSubmissionPdfType.APPROVAL)}
            disabled={!afterApprovedStatus}
          >
            {label}
          </Button>

          <button
            className={`${
              afterApprovedStatus ? 'hover-shadow1' : 'cursor-not-allowed'
            } p-1 rounded w-7 h-7 text-shade-blue cursor-pointer mb-2`}
            disabled={!afterApprovedStatus}
            onClick={() => onSendPdfEmail(LoanSubmissionPdfType.APPROVAL)}
          >
            <EnvelopeIcon className="w-5 h-5" />
          </button>
        </div>

        <div className="flex gap-1 mb-2 items-center">
          <Button
            className="w-full"
            color="gray"
            loading={action === `Pdf-${LoanSubmissionPdfType.APPROVAL_ALL}`}
            onClick={() => onGeneratePdf(LoanSubmissionPdfType.APPROVAL_ALL)}
            disabled={!afterApprovedStatus}
          >
            {`${label}-All`}
          </Button>

          <button
            className={`${
              afterApprovedStatus ? 'hover-shadow1' : 'cursor-not-allowed'
            } p-1 rounded w-7 h-7 text-shade-blue cursor-pointer mb-2`}
            disabled={!afterApprovedStatus}
            onClick={() => onSendPdfEmail(LoanSubmissionPdfType.APPROVAL_ALL)}
          >
            <EnvelopeIcon className="w-5 h-5" />
          </button>
        </div>

        {loanStatusConstants.closeStatus.indexOf(loanDetail.loanStatus) !== -1 && (
          <div className="flex gap-1 items-center">
            <Button
              className="w-full"
              color="gray"
              loading={action === `Pdf-${LoanSubmissionPdfType.COMMITMENT}`}
              onClick={() => onGeneratePdf(LoanSubmissionPdfType.COMMITMENT)}
            >
              Generate Commitment
            </Button>

            <button
              className={`${
                afterApprovedStatus ? 'hover-shadow1' : 'cursor-not-allowed'
              } p-1 rounded w-7 h-7 text-shade-blue cursor-pointer mb-2`}
              onClick={() => onSendPdfEmail(LoanSubmissionPdfType.COMMITMENT)}
            >
              <EnvelopeIcon className="w-5 h-5" />
            </button>
          </div>
        )}
        <div className="flex gap-1 mb-2 items-center">
          <Button
            className="w-full"
            color="gray"
            loading={action === `Pdf-${LoanSubmissionPdfType.Loan_SETUP_SUSPENSE}`}
            onClick={() => onGeneratePdf(LoanSubmissionPdfType.Loan_SETUP_SUSPENSE)}
          >
            {`Loan Setup Suspense`}
          </Button>

          <button
            className={`${
              true ? 'hover-shadow1' : 'cursor-not-allowed'
            } p-1 rounded w-7 h-7 text-shade-blue cursor-pointer mb-2`}
            disabled={!true}
            onClick={() => onSendPdfEmail(LoanSubmissionPdfType.Loan_SETUP_SUSPENSE)}
          >
            <EnvelopeIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    )
  }

  const onSubmitToLoanSetup = async () => {
    setAction('onSubmitToLoanSetup')
    setLoading(true)
    const res = await postSubmitToLoanSetup()
    setAction('')
    setLoading(false)
    if (res.success === 1) {
      dispatch(
        setLoanDetail({
          submitToLoanSetup: 1,
          loanStatus: res.loanStatus,
          byteproFileName: res.byteproFileName,
        }),
      )
      setSubmissions(res.submissions)
    } else if (res.errors) {
      const contentElement = getLoanStatusChangeErrorMessage(res.errors, res.targetStatus)
      await confirm(contentElement, { titleYes: 'Confirm', titleNo: false })
    }
  }

  const onSubmitClosedLoanReview = async () => {
    setAction('onSubmitClosedLoanReview')
    setLoading(true)
    const res = await postSubmitClosedLoanReview()
    if (res.success === 1) {
      dispatch(
        setLoanDetail({
          loanStatus: res.loanStatus,
          byteproFileName: res.byteproFileName,
        }),
      )
    }
    setAction('')
    setLoading(false)
  }

  const onSubmitClosingConditionCleared = async () => {
    setAction('onSubmitClosingConditionCleared')
    setLoading(true)
    await postSubmitClosingConditionCleared()
    setAction('')
    setLoading(false)
  }

  const onSubmitConditions = async () => {
    if (
      currentTemplate === 0 &&
      restrictionConfig.submit_to_underwriter.conditionTemplateRequired.enabled &&
      restrictionConfig.submit_to_underwriter.isActive
    ) {
      return toast(CONDITION_TEMPLATE_REQUIRED, { type: 'error' })
    }
    if (
      parties?.underwriter?.email?.length > 0 ||
      !restrictionConfig.submit_to_underwriter.underwriterRequired.enabled ||
      !restrictionConfig.submit_to_underwriter.isActive
    ) {
      setAction('onSubmitConditions')
      setLoading(true)
      const res = await postSubmitConditions()
      setLastSubmittedAt(Date.now())
      setAction('')
      setLoading(false)
      if (res.success === 1) {
        dispatch(
          setLoanDetail({
            submitConditions: 1,
            loanStatus: res.loanStatus,
          }),
        )
        setSubmissions(res.submissions)
      } else if (res.errors) {
        const contentElement = getLoanStatusChangeErrorMessage(res.errors, res.targetStatus)
        await confirm(contentElement, { titleYes: 'Confirm', titleNo: false })
      }
    } else {
      toast('Underwriter is required!', { type: 'error' })
    }
  }

  const onSubmitCTCReview = async () => {
    setAction('onSubmitCTCReview')
    setLoading(true)
    const res = await submitCTCReview()
    if (res.success === 1) {
      dispatch(
        setLoanDetail({
          loanStatus: res.loanStatus,
        }),
      )
      setSubmissions(res.submissions)
    }
    setAction('')
    setLoading(false)
  }

  const onSubmitLoan = async () => {
    if (
      parties?.accountExecutive?.email?.length > 0 ||
      !restrictionConfig.submit_loan.accountExecutiveRequired.enabled ||
      !restrictionConfig.submit_loan.isActive
    ) {
      setAction('onSubmitLoan')
      setLoading(true)
      const res = await postSubmitToAE()
      setAction('')
      setLoading(false)
      if (res.success) {
        // const { submitToAE } = loanDetail
        // if (submitToAE === 0) setShowRushSubmit(true)
        dispatch(
          setLoanDetail({
            submitToAE: 1,
            loanStatus: res.loanStatus,
          }),
        )
      } else if (res.errors) {
        const contentElement = getLoanStatusChangeErrorMessage(res.errors, res.targetStatus)
        await confirm(contentElement, { titleYes: 'Confirm', titleNo: false })
      }
    } else {
      toast('Account Executive is required!', { type: 'error' })
    }
  }

  const onSubmitToUnderwriter = async () => {
    if (
      currentTemplate === 0 &&
      restrictionConfig.submit_to_underwriter.conditionTemplateRequired.enabled &&
      restrictionConfig.submit_to_underwriter.isActive
    ) {
      return toast(CONDITION_TEMPLATE_REQUIRED, { type: 'error' })
    }

    if (
      parties?.underwriter?.email?.length > 0 ||
      !restrictionConfig.submit_to_underwriter.underwriterRequired.enabled ||
      !restrictionConfig.submit_to_underwriter.isActive
    ) {
      setAction('onSubmitToUnderwriter')
      setLoading(true)
      const res = await postSubmitToUnderwriter()
      setLastSubmittedAt(Date.now())
      setAction('')
      setLoading(false)
      if (res.success === 1) {
        dispatch(
          setLoanDetail({
            submitToUnderwriter: 1,
            loanStatus: res.loanStatus,
          }),
        )
        setSubmissions(res.submissions)
      } else if (res.errors) {
        const contentElement = getLoanStatusChangeErrorMessage(res.errors, res.targetStatus)
        await confirm(contentElement, { titleYes: 'Confirm', titleNo: false })
      }
    } else {
      toast('Underwriter is required!', { type: 'error' })
    }
  }

  const onRushSubmit = () => {
    setShowRushSubmit(true)
  }

  const renderSubmitActions = () => {
    const { submitToAE, submitToLoanSetup, submitConditions, submitToUnderwriter, loanStatus } = loanDetail
    let disableSubmitToAE = false,
      disableSubmitToLoanSetup = false,
      disableSubmitConditions = false,
      disableSubmitToUnderwriter = false

    let showSubmitToAE = true,
      showSubmitToLoanSetup = true,
      showSubmitConditions = true,
      showSubmitToUnderwriter = true

    const loanSetupOnHold = loanStatusConstants.loanSetupUnHoldStatus.indexOf(loanStatus) === -1

    if (renderSubmitRestrictions !== null && !overrideLoanSubmissionRestriction) {
      disableSubmitToAE = true
      disableSubmitToLoanSetup = true
    }
    if (loanSetupOnHold) {
      if (!hasPermission('MANAGE_LOAN_PROGRESS_RESTRICTION')) {
        disableSubmitToAE = true
      }
    }

    if (submitToAE === 0) {
      disableSubmitToLoanSetup = true
    }

    if (submitToAE === 1 && !brokerProfile) {
      disableSubmitToAE = true
    }

    // if (submitConditions === 0) {
    //   if (loanStatusConstants.suspendedStatus.indexOf(loanStatus) === -1) disableSubmitConditions = true
    // }

    // if (submitConditions === 1 && brokerProfile) {
    //   if (loanStatusConstants.brokerCanResubmitConditions.indexOf(loanStatus) === -1) disableSubmitConditions = true
    // }

    // if (loanStatus === 'underwriterReview') {
    //   disableSubmitConditions = true
    // }

    if (action !== '' || loanStatus === 'withdrawn') {
      disableSubmitToAE = true
      disableSubmitToLoanSetup = true
      // disableSubmitConditions = true
      // disableSubmitToUnderwriter = true
    }

    if (restrictionConfig.submit_to_underwriter.disabledAtStatus?.includes(loanStatus)) {
      disableSubmitToUnderwriter = true
    }

    if (restrictionConfig.submit_condition.disabledAtStatus?.includes(loanStatus)) {
      disableSubmitConditions = true
    }

    if (brokerProfile) {
      showSubmitToLoanSetup = false
      if (submitToLoanSetup !== 0) {
        if (loanStatus === 'loanSetupOnHold') {
          showSubmitToLoanSetup = true
        }
      }
    }

    if (submitToLoanSetup === 0) {
      showSubmitConditions = false
      showSubmitToUnderwriter = false
    } else {
      showSubmitToAE = false
    }
    if (!hasPermission('CAN_SUBMIT_TO_UNDERWRITER')) {
      showSubmitToUnderwriter = false
    }

    let showSubmitClosedLoanReview = false
    let disableSubmitClosedLoanReview = false

    let showClosingConditionCleared = false

    if (loanDetail.loanType === LoanType.CORRESPONDENT) {
      if (loanDetail.loanStatus === 'clearToClose') {
        showSubmitClosedLoanReview = true
      }
      if (
        loanDetail.loanStatus === 'closedLoanReview' &&
        [AccountType.ADMIN, AccountType.CLOSER].includes(profile.accountType)
      ) {
        showClosingConditionCleared = true
      }
    }

    if (renderSubmitRestrictions !== null && !overrideLoanSubmissionRestriction) disableSubmitClosedLoanReview = true

    let submitToLoanSetupButtonLabel = submitToLoanSetup === 0 ? 'Submit to LoanSetup' : 'ReSubmit to LoanSetup'
    if (loanDetail.loanType === LoanType.CORRESPONDENT) {
      submitToLoanSetupButtonLabel =
        submitToLoanSetup === 0 ? 'Submit Credit Loan Review' : 'ReSubmit Credit Loan Review'
    }

    return (
      <div className="p-3 shadow w-96">
        <div className="border-b mb-4 text-gray-800 font-bold text-[18px] flex gap-4 items-center">
          <span>Submissions</span>
          {!!submitToAE && (
            <Checkbox
              className="w-fit"
              title="Rush"
              id={`Rush`}
              checked={loanDetail.rush}
              disabled={![AccountType.ADMIN].includes(profile.accountType)}
              onChange={(value) => onChangeRush(value)}
              history={hasPermission('CAN_VIEW_AUDIT_LOG')}
              showHistory={() => showLoanProcessHistory('rush')}
            />
          )}
        </div>
        {!!submitToAE && (
          <div
            className="bg-sky-100 border border-sky-400 text-sky-700 px-4 py-3 rounded text-[15px] mb-4"
            role="alert"
          >
            If you require expedited processing of this file,{' '}
            <span
              className="font-semibold text-shade-blue cursor-pointer hover:underline inline text-[16px]"
              onClick={onRushSubmit}
            >
              <svg className="animate-bounce w-6 h-6 inline mr-2">
                <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
              </svg>
              Click Here
            </span>{' '}
            to submit your request. Our team will prioritize the processing of this file to ensure a swift turnaround.
          </div>
        )}

        {showSubmitToAE && restrictionConfig.submit_loan.visibleAccountTypes?.includes(accountType) && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitLoan'}
              disabled={disableSubmitToAE}
              onClick={onSubmitLoan}
            >
              {submitToAE ? 'ReSubmit Loan' : 'Submit Loan'}
            </Button>
          </div>
        )}

        {showSubmitToLoanSetup && restrictionConfig.submit_to_loan_setup.visibleAccountTypes?.includes(accountType) && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitToLoanSetup'}
              disabled={disableSubmitToLoanSetup}
              onClick={onSubmitToLoanSetup}
            >
              {submitToLoanSetupButtonLabel}
            </Button>
          </div>
        )}

        {showSubmitToUnderwriter && restrictionConfig.submit_to_underwriter.visibleAccountTypes?.includes(accountType) && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitToUnderwriter'}
              disabled={disableSubmitToUnderwriter}
              onClick={onSubmitToUnderwriter}
            >
              {submitToUnderwriter === 0 ? 'Submit to Underwriter' : 'ReSubmit to Underwriter'}
            </Button>
          </div>
        )}

        {showSubmitConditions && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitConditions'}
              disabled={disableSubmitConditions}
              onClick={onSubmitConditions}
            >
              {submitConditions === 0 ? 'Submit Conditions' : 'ReSubmit Conditions'}
            </Button>
          </div>
        )}
        {submitToUnderwriter !== 0 &&
          hasPermission('CAN_SUBMIT_TO_UNDERWRITER') &&
          restrictionConfig.submit_cTC_reivew.isActive && (
            <div className="mb-2">
              <Button className="w-full" loading={action === 'onSubmitCTCReview'} onClick={onSubmitCTCReview}>
                Submit CTC Review
              </Button>
            </div>
          )}
        {showSubmitClosedLoanReview && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitClosedLoanReview'}
              onClick={onSubmitClosedLoanReview}
              disabled={disableSubmitClosedLoanReview}
            >
              Submit Closing Package Review
            </Button>
          </div>
        )}
        {showClosingConditionCleared && (
          <div className="mb-2">
            <Button
              className="w-full"
              loading={action === 'onSubmitClosingConditionCleared'}
              onClick={onSubmitClosingConditionCleared}
            >
              Closing Conditions Cleared
            </Button>
          </div>
        )}
      </div>
    )
  }

  const renderSubmissions = useMemo(() => {
    return (
      <div className="p-3 shadow w-full">
        <div className="border-b mb-4 text-gray-800 font-bold text-[17px] flex justify-between items-center">
          <span>Submission Histories</span>
        </div>
        <div className="parties-container mb-2 overflow-auto shadow-md sm:rounded-lg">
          <table className="text-sm text-left text-gray-900 dark:text-gray-400 pl-6 w-full">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">No</th>
                <th className="px-6 py-3">Action</th>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Date</th>
                <th className="px-6 py-3">Details</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 === 1 && 'bg-slate-50'}`}>
                    <td className="px-3 py-2 pl-6">{index + 1}</td>
                    <td className="px-3 py-2">{item.action}</td>
                    <td className="px-3 py-2">{item.email}</td>
                    <td className="px-3 py-2">{formatTime(item.time)}</td>
                    <td className="px-3 py-2">{item.detail}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }, [submissions])

  const renderSubmitRestrictions = useMemo(() => {
    const appDetail = step.overview.application.detail
    const { loanType, closeParties, parties } = loanDetail
    // if (submitToLoanSetup !== 0) return null
    let errors = []
    const restriction = restrictionConfig?.submit_loan
    if (appDetail.dataComplete === -1 && restriction?.dataComplete?.enabled && restriction?.isActive)
      errors.push(`Loan Application -> Data Complete`)
    if (appDetail.signApplication === -1 && restriction?.signApplication?.enabled && restriction?.isActive)
      errors.push(`Loan Application -> Sign Application`)
    if (assetInfoValidation === false && restriction?.assetLiability?.enabled && restriction?.isActive)
      errors.push(`Loan Application -> Asset & Liability`)
    if (loanType !== LoanType.CORRESPONDENT) {
      if (appDetail.creditScorePull === -1 && restriction?.creditScorePull?.enabled && restriction?.isActive)
        errors.push(`Loan Application -> Pull Credit Score`)
      else {
        const scoreMatch = isSameStructureApplicationCreditScore()
        if (!scoreMatch.same && restriction?.scoreMatch?.enabled && restriction?.isActive)
          errors.push(`Loan Structure -> ${scoreMatch.error}`)
      }
    }
    if (parties.loanOfficer?.name?.length > 0) {
    } else if (restriction?.loanOfficerRquired?.enabled && restriction?.isActive) {
      errors.push(`Parties -> Loan Officer is Required!`)
    }
    if (closeParties.titleCompany?.FirstName?.length > 0) {
    } else if (restriction?.titleCompanyRequired?.enabled && restriction?.isActive) {
      errors.push(`Parties -> Title Company is Required!`)
    }
    if (closeParties.hazardInsurance?.FirstName?.length > 0) {
    } else if (restriction?.hazardCompanyRequired?.enabled && restriction?.isActive) {
      errors.push(`Parties -> Hazard Insurance Company is Required!`)
    }

    if (!scopeOfWork && restriction?.scopeOfWork?.enabled && restriction?.isActive) {
      errors.push(`The submission of the Borrower's Budget & Scope of Work Form is required.`)
    }

    if (errors.length === 0) return null
    return (
      <div className="flex-1 min-w-[300px]">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]"
          role="alert"
        >
          <div className="font-variation-settings-600">Following steps are required before Submission Process.</div>
          <div className="mt-3">
            {errors.map((item, index) => {
              return (
                <div className="" key={index}>
                  - {item}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }, [loanDetail, step, scopeOfWork])

  const onCategoryChange = (value: string) => {
    value = value.split(' (')[0]
    const index = categories.indexOf(value)
    const newStatus = cloneDeep(categoryOpenStatus)
    newStatus[value] = true
    setCategoryOpenStatus(newStatus)
    if (categoriesRef.current[index]) {
      categoriesRef.current[index]?.scrollIntoView({ behavior: 'auto' })
    }
  }

  const onChangeRush = async (value: boolean) => {
    if (value) {
      const content = (
        <div className="mb-4 text-[18px]">
          Are you sure you want to expedite the loan process?
          <br />
          Please ensure that the rush processing fee has been paid.
        </div>
      )
      const result = await confirm(content)
      if (!result) return
    }
    setLoading(true)
    await saveLoanProcess({ rush: value, historyAction: 'Log' })
    dispatch(setLoanDetail({ rush: value }))
    setLoading(false)
  }

  const showLoanProcessHistory = (key: string) => {
    const options = {
      table: 'LoanProcess',
      field: key,
      keys: {
        field: key,
        loanNumber: loanDetail.loanNumber,
      },
    }
    openAuditLog(options)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="LoanSubmission-container px-2">
        <div className="max-w-screen-2xl m-auto">
          <div className="relative bg-white shadow1 rounded mb-4 p-4">
            <LayoutLoading show={loading} />
            <div className="parties-container overflow-auto mb-4 shadow rounded">
              <table className="table-auto w-full rounded">
                <thead className="text-[15px] bg-gray-100">
                  <tr>
                    <th className="border p-1">
                      <span className="flex flex-wrap gap-4 items-center justify-center">
                        <span>Parties</span>
                        {loanDetail.byteproFileName !== 0 && hasPermission('MANAGE_PARTIES') && (
                          <Link to={`/parties/${loanDetail.loanNumber}`} target="_blank">
                            <span className="flex gap-2 items-center text-shade-blue text-[13px] hover:underline">
                              <span>View All</span>
                              <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                            </span>
                          </Link>
                        )}
                      </span>
                    </th>
                    <th className="border p-1">Name</th>
                    <th className="border p-1">Email</th>
                    <th className="border p-1">Phone</th>
                    <th className="border p-1">Phone Ext.</th>
                  </tr>
                </thead>
                <tbody className="text-[14px] text-gray-900">
                  {Object.keys(parties).map((key, index) => {
                    const { name, email, phone, phoneExt } = parties[key]
                    const bgCn = index % 2 ? 'bg-gray-50' : ''
                    return (
                      <tr key={index} className={bgCn}>
                        <td className="border p-1 pl-2">{partiesMap[key]}</td>
                        <td className="border p-1 pl-2">{name}</td>
                        <td className="border p-1 pl-2">
                          {key === 'creator' ? (
                            <span className="font-variation-settings-600 text-[14px]">
                              <CopyableText>{email}</CopyableText>
                            </span>
                          ) : (
                            <LoanProcessSelection id={key} type={'party'} />
                          )}
                        </td>
                        <td className="border p-1 pl-2">
                          <CopyableText>{phone}</CopyableText>
                        </td>
                        <td className="border p-1 pl-2">{phoneExt}</td>
                      </tr>
                    )
                  })}
                  {loanDetail.byteproFileName !== 0 &&
                    Object.keys(closingPartiesMap).map((key, index) => {
                      const bgCn = index % 2 ? '' : 'bg-gray-50'
                      const item = loanDetail.closeParties[key]
                      return (
                        <tr key={`closing-${index}`} className={`${bgCn} border-b`}>
                          <td className="border p-1 pl-2">{closingPartiesMap[key]}</td>
                          <td className="border p-1 pl-2">
                            {item.FirstName ? `${item.FirstName} ${item.LastName}` : ``}
                          </td>
                          <td className="p-1 border">
                            <ClosingParty id={key} />
                          </td>
                          <td className="border p-1 pl-2">
                            <CopyableText>{item.WorkPhone}</CopyableText>
                          </td>
                          <td className="border p-1 pl-2"></td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className={`flex flex-col col-span-2 p-2 md:p-4 shadow rounded-md text-[15px]`} key={'others'}>
              <div className="overflow-auto">
                <div className="flex flex-wrap text-gray-900 items-center text-[15px]">
                  <span className="mr-4">Estimated Closing Date:</span>
                  <span>
                    <LoanProcessSelection type={'closingDate'} />
                  </span>
                </div>
                <div className="flex flex-wrap text-gray-900 items-center text-[15px] mt-2">
                  <span className="mr-4">Email Notification Contacts:</span>
                  <span>
                    <LoanProcessSelection type={'emailContacts'} />
                  </span>
                </div>
              </div>
            </div>
            <div className="conditions-container overflow-show mt-4">
              <div className="mb-4 flex flex-wrap items-center gap-4">
                <ButtonGroup title={countCategories} onChange={onCategoryChange} value={''} />
              </div>
              <div className="flex flex-wrap border-b justify-between items-center mb-4">
                <p className="text-lg font-bold text-gray-800 flex items-center gap-3">
                  Open Conditions
                  {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
                </p>

                <div className="flex mb-2 flex-wrap items-center">
                  <PermissionGate permission={'ADD_HOC_CONDITION'} hidden={true}>
                    <AddHoc onAfterSubmit={onAddCondition} />
                    <AddConditionsFromTemplates
                      defaultConditions={conditions.map(({ no }) => no)}
                      onAfterSubmit={onAddConditionsFromTemplates}
                    />
                  </PermissionGate>
                  <PermissionGate permission={'CHANGE_LOAN_CONDITION_TEMPLATES'} hidden={true}>
                    <span className="w-80 mb-2">
                      <Select2
                        name="chooseTemplate"
                        id="chooseTemplate"
                        value={String(currentTemplate)}
                        options={templates}
                        title="Choose Template"
                        hasDefaultOption={currentTemplate == 0}
                        defaultOptionText="Origin"
                        onChange={onChangeConditionTemplate}
                        history={hasPermission('CAN_VIEW_AUDIT_LOG')}
                        showHistory={onShowTemplateHistory}
                      />
                    </span>
                  </PermissionGate>
                </div>
              </div>

              {currentTemplate > 0 && (
                <>
                  <div className="flex flex-wrap justify-center mx-auto space-x-4 items-center mb-3">
                    <div className="w-72 mb-4">
                      <Select2
                        id="filterByCategory"
                        value={String(filterCategory)}
                        options={categories}
                        title="Filter by Category"
                        hasDefaultOption
                        defaultOptionText="All"
                        onChange={onChangeFilterCategory}
                      />
                    </div>
                    <div className="w-72 mb-4">
                      <MultiSelect
                        value={respFilter}
                        title="Filter by Responsibility"
                        id="filterByResponsibility"
                        options={condConstant.respon}
                        onChange={(value) => setRespFilter(value)}
                      />
                    </div>
                    <div className="w-72 mb-4 flex items-center">
                      <Checkbox
                        color="gray"
                        title="Not Cleared"
                        id={`cleared-filter`}
                        checked={filterCleared}
                        onChange={(value) => setFiltercleared(value)}
                      />
                      <span className="mx-2">
                        <ConditionOrderChange
                          conditions={conditions}
                          conditionOrder={conditionOrder}
                          onAfterSubmit={onSetConditionOrder}
                        />
                      </span>
                      <span className="mx-2">
                        <DeleteConditions
                          conditions={conditions}
                          conditionOrder={conditionOrder}
                          onAfterSubmit={onRemoveMultiConditions}
                        />
                      </span>
                    </div>
                    <div className="w-72 mb-4">
                      <Select2
                        id="conditionSortBy"
                        value={String(conditionSortBy)}
                        options={['Type', 'Class', 'Responsibility']}
                        title="Sort By"
                        hasDefaultOption
                        defaultOptionText=""
                        onChange={(value) => setConditionSortBy(value)}
                      />
                    </div>
                  </div>

                  {categories.map((category, index) => {
                    const selected = categoryOpenStatus[category]
                    if (filterCategory && filterCategory != category) return null
                    return (
                      <div className="mb-3" key={category} ref={(el) => (categoriesRef.current[index] = el)}>
                        <h2>
                          <button
                            type="button"
                            className={`${
                              selected ? 'bg-shade-blue text-white hover:text-gray-900' : ''
                            } flex justify-between items-center p-5 w-full font-medium text-left hover:text-gray-900 text-gray-900 rounded-t-xl border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 hover:ring-4 hover:ring-gray-200`}
                            onClick={() => onClickCategory(category)}
                          >
                            <span>
                              {category} ({getCountPerCategory(category)})
                            </span>
                            <span className="">
                              {selected ? (
                                <span className="">
                                  <MinusIcon className="w-5 h-5 shrink-0" />
                                </span>
                              ) : (
                                <PlusIcon className="w-5 h-5 shrink-0 hover:text-gray-900" />
                              )}
                            </span>
                          </button>
                        </h2>
                        <div>{renderCondition(category)}</div>
                      </div>
                    )
                  })}
                </>
              )}
              {currentTemplate === 0 && renderOriginalTemplate()}
              {currentTemplate !== -1 && (
                <div className="mt-6 flex flex-wrap gap-4">
                  <div>
                    {renderSubmitRestrictions}
                    {renderSubmitRestrictions !== null && [AccountType.ADMIN].includes(profile.accountType) && (
                      <div className="mt-4 ml-2">
                        <Checkbox
                          className={`text-red-700`}
                          id={'overrideRestriction'}
                          title={'Override Loan Submission Restrictions'}
                          key={'overrideRestriction'}
                          value={overrideLoanSubmissionRestriction}
                          onChange={(value) => setOverrideLoanSubmissionRestriction(value)}
                        />
                      </div>
                    )}
                  </div>
                  {renderSubmitActions()}
                  {renderPdfActions()}

                  {isNeedToResubmit === true ? (
                    <div>
                      <div
                        className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4 text-[15px]"
                        role="alert"
                      >
                        The conditions and documents are updated. Please submit again.
                      </div>
                    </div>
                  ) : null}
                  {renderSubmissions}
                </div>
              )}
            </div>
          </div>
        </div>

        <LoanDetailsSlide />
        {action !== 'chooseTemplate' && currentTemplate > 0 && (
          <NotifyBorrowerSlide conditions={conditions} brokerProfile={brokerProfile} />
        )}
        {!brokerProfile && <UnderwritingSlide />}
        <DocumentsSlide
          loading={loading}
          setLoading={setLoading}
          documents={documents}
          onAdd={onAddDocuments}
          onUpdate={onUpdateDocuments}
          onRemove={(document: any) => onRemoveDocument(document, true)}
          onSelectedRemove={(selectedKeys: string[]) => onRemoveDocuments(selectedKeys)}
          onOpen={(document: any) => onOpenDocument(document)}
          onOpenDocHistory={setHistoryDocument}
          brokerProfile={brokerProfile}
          canDeleteDocumentPermanently={canDeleteDocumentPermanently}
          submitToLoanSetup={loanDetail.submitToLoanSetup}
        />
        <ChooseDocumentsForCondition
          documents={documents}
          conditionNo={editingConditionNo}
          isOpen={editingConditionNo != 0}
          onAfterSubmit={onUpdateConditionDocuments}
          onClose={onCloseChoosingDocuments}
          onOpenDocument={onOpenDocument}
        />
      </div>
      {sendPdfEmailType !== null && (
        <SendPdfEmailDialog type={sendPdfEmailType} conditions={conditions} onClose={() => setSendPdfEmailType(null)} />
      )}
      {historyDocument && <DocumentHistoryModal document={historyDocument} onClose={() => setHistoryDocument(null)} />}
      {showRushSubmit && (
        <RushSubmitModal
          onClose={(isPaid: true) => {
            setShowRushSubmit(false)
            isPaid &&
              dispatch(
                setLoanDetail({
                  rush: true,
                }),
              )
          }}
        />
      )}
      <ScrollTop />
    </DndProvider>
  )
}
